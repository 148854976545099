export const ContactsData = [
    [
        { 
            name: "contact.first_name", 
            label: "Имя", 
            placeholder: "Иван",
            type: "text",
            maskConfig: { mask: /^[A-Za-zА-Яа-яЁё\s]*$/ }
        },
        { 
            name: "contact.surname", 
            label: "Фамилия", 
            placeholder: "Иванов",
            type: "text",
            maskConfig: { mask: /^[A-Za-zА-Яа-яЁё\s]*$/ }
        },
        { 
            name: "contact.patronymic", 
            label: "Отчество", 
            placeholder: "Иванович",
            type: "text",
            maskConfig: { mask: /^[A-Za-zА-Яа-яЁё\s]*$/ }
        },
        { 
            name: "contact.phone", 
            label: "Телефон", 
            placeholder: "Введите",
            type: "text",
            maskConfig: { mask: '+{7}-000-000-00-00' }, 
        },
        { 
            name: "contact.email", 
            label: "Почта", 
            placeholder: "Введите",
            type: "email" 
        },
    ],
];