import styled from "styled-components";

export const DropdownButton = styled.div`
    color: #282A42;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    & > div {
        transform: rotate(-90deg);
    }
`;