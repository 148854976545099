import { StyledNotifications, StyledNotificationsContent, StyledNotificationsIcon, StyledNotificationsWrapper } from "./style"
import NotificationsUser from "shared/components/Icons/IconsList/rightSidebar/notificationsUser"

type IPropsData = {
    name: string, 
    location?: string,
    contact: string,
    comments?: string,
    paymentStatus: string,
    paymentAmount: string,
    time: string
}

type CardNotificationsProps = {
    data: IPropsData,
    setBookingForm: (status: boolean) => void;
}

export const CardNotifications = ({data, setBookingForm}: CardNotificationsProps) => {
    return (
        <StyledNotifications onClick={() => setBookingForm(true)}>
            <StyledNotificationsWrapper>
                    <StyledNotificationsIcon>
                        <NotificationsUser />
                    </StyledNotificationsIcon>
                    <StyledNotificationsContent>
                        <div className="title-card">{data.name}</div>
                        <div className="notifications-text information">{data.location}, {data.contact}</div>
                        <div className="notifications-text comments">{data.comments}</div>
                        <div className="notifications-text status">{data.paymentStatus}</div>
                        <div className="notifications-text time">{data.time}</div>
                        <div className="notifications-text payment">{data.paymentAmount}</div>
                    </StyledNotificationsContent>
            </StyledNotificationsWrapper>
        </StyledNotifications>
    )
}