import React, { useState } from "react"
import { Icon } from "shared/components/Icons";
import { useSidebar } from "./components/SidebarProvider/SidebarProvider";
import { booking, notifications } from "./statics";
import { CardNotifications } from "./components/CardNotifications";
import { Box, StyledBookingForm, StyledItem, StyledItemWrapper, StyledSidebar } from "./style";
import { Input } from "shared/components/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeSidebar } from "shared/redux/slice/sidebar";
import { RootState } from "shared/redux/store";
import { AccordionCustom } from "./components/AccordionCustom";
import { Tab, Tabs } from "shared/components/Tabs";

export const RightSidebar: React.FC = () => {
    const dispatch = useDispatch();
    const { isOpen, component } = useSelector((state: RootState) => state.sidebar);

    const handleCloseSidebar = () => {
        dispatch(closeSidebar());
    };

    const [bookingForm, setBookingForm] = useState(false);
    const { isExpanded, toggleSidebar } = useSidebar();

    if(isOpen && component === "BookingComponent") {
        // <BookingSidebar />
        return (
            <Box isExpanded={isOpen}>
                <div className="sibedar-top-row">
                    <StyledItemWrapper>
                        <StyledItem>
                            <div className="wrapper">
                                <button>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M14.5 17L9.5 12L14.5 7" stroke="#8181A5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                                <span>Назад</span>
                            </div>
                        </StyledItem>
                        <StyledItem>
                            <button onClick={handleCloseSidebar}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
                                    <rect x="38.5" y="38.5" width="38" height="38" rx="7.5" transform="rotate(-180 38.5 38.5)"/>
                                    <path d="M13.3431 13.6569L24.6569 24.9706M24.6569 13.6569L13.3431 24.9706" stroke="#FC3F1D" strokeLinecap="round"/>
                                </svg>
                            </button>
                            <button>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect x="16" y="4" width="4" height="16" rx="1" fill="#8181A5"/>
                                    <rect x="4" y="4" width="10" height="16" rx="1" fill="#8181A5"/>
                                </svg>
                            </button>
                            <button onClick={handleCloseSidebar}>
                                <Icon className="back" name="arrowRight" style={{transform: isOpen ? "rotate(180deg)" : "rotate(0deg)"}} />
                            </button>
                        </StyledItem>
                    </StyledItemWrapper>
                    <div className="line"></div>
                    <Tabs>
                        <Tab label="Физическое лицо">
                            <AccordionCustom isOpen={true} title="1. Общая информация">
                                hello
                            </AccordionCustom>
                            <AccordionCustom title="2. Бронирование">
                                hello
                            </AccordionCustom>
                            <AccordionCustom title="3. Оплата">
                                hello
                            </AccordionCustom>
                            <AccordionCustom title="4. Документы">
                                hello
                            </AccordionCustom>
                        </Tab>
                        <Tab label="Юридическое лицо">
                            <AccordionCustom title="1. Общая информация">
                                hello
                            </AccordionCustom>
                            <AccordionCustom title="2. Бронирование">
                                hello
                            </AccordionCustom>
                            <AccordionCustom title="3. Оплата">
                                hello
                            </AccordionCustom>
                            <AccordionCustom title="4. Документы">
                                hello
                            </AccordionCustom>
                        </Tab>
                    </Tabs>
                </div>
            </Box>
        )
    }
    if(bookingForm) {
        return (
            <Box isExpanded={isExpanded}>
                <div className="sibedar-top-row">
                    <StyledItem onClick={() => setBookingForm(false)}>
                        <button>
                            <Icon className="back" name="arrowRight" style={{transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)"}} />
                        </button>
                    </StyledItem>
                    <div className="line"></div>
                    <StyledBookingForm>
                        <div className="row-form">
                            <Input
                                name=""
                                type="text" 
                                label="Номер заказа" 
                                value="12351646516"
                            />
                            <Input 
                                name=""
                                type="text" 
                                value="Активно"
                            />
                        </div>
                        <div>
                            <Input 
                                name=""
                                type="text" 
                                label="ФИО" 
                                value="12351646516"
                            />
                            <div className="open">Открыть профиль</div>
                        </div>
                        <Input 
                            name=""
                            type="text" 
                            label="ФИО" 
                            value="12351646516"
                        />
                        <Input 
                            name=""
                            type="text" 
                            label="Контактный телефон" 
                            value="+7 932 234 23 43"
                        />
                        <Input 
                            name=""
                            type="text" 
                            label="Контактный Email" 
                            value="Ivanov.Ivan@mail.ru"
                        />
                        <Input 
                            name=""
                            type="text" 
                            label="Помещение" 
                            value="Темный зал"
                        />
                        <Input 
                            name=""
                            type="text" 
                            label="Количество человек" 
                            value="8"
                        />
                        <Input 
                            name=""
                            type="text" 
                            label="Дата" 
                            value="Понедельник, 28 октября 2023"
                        />
                        <div className="row-form">
                            <Input 
                                name=""
                                type="text" 
                                label="Время начала" 
                                value="16:00"
                            />
                            <Input 
                                name=""
                                type="text" 
                                label="Время конца" 
                                value="21:00"
                            />
                        </div>
                        <Input 
                            name=""
                            type="text" 
                            label="Дополнения" 
                            value="Пиво (x5); чипсы (x5)"
                        />
                        <Input 
                            name=""
                            type="text" 
                            label="Способ оплаты" 
                            value="Онлайн оплата"
                        />
                        <Input 
                            name=""
                            type="text" 
                            label="Сумма" 
                            value="₽15 000"
                        />
                        <div className="row-form">
                            <Input 
                                name=""
                                type="text" 
                                label="Сумма" 
                                value="Оплачено"
                            />
                            <Input 
                                name=""
                                type="text" 
                                label="Дата" 
                                value="12.10.2023, 10:30"
                            />
                        </div>
                        <Input 
                            name=""
                            type="text" 
                            label="Комментарий" 
                            value="Возможно опоздание"
                        />
                    </StyledBookingForm>
                </div>
            </Box>
        )
    }
    return (
        <Box isExpanded={isExpanded}>
            <div className="sibedar-top-row">
                <StyledItem onClick={toggleSidebar}>
                    <button>
                        <Icon className="back" name="arrowRight" style={{transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)"}} />
                    </button>
                </StyledItem>
                <div className="line"></div>
            </div>
            {isExpanded && (
                <StyledSidebar>
                    <div className="title">
                        Уведомления
                        <div className="badge">3</div>
                    </div>
                    {notifications.map((item, index) => (
                        <CardNotifications setBookingForm={setBookingForm} data={item} />
                    ))}
                    <div className="line"></div>
                    <div className="title">Предстоящие брони</div>
                    {booking.map((item, index) => (
                        <CardNotifications setBookingForm={setBookingForm} data={item} />
                    ))}
                </StyledSidebar>
            )}
            {!isExpanded &&
                <>
                    <StyledItem>
                        <button>
                            <Icon name="notification" />
                        </button>
                    </StyledItem>
                </>
            }
        </Box>
    );
};