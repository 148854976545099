import React from 'react';

const NotificationsUser = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M21 12.0026H17M19 14.0026L19 10.0026" stroke="#363853" strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M3 19.114C3 16.701 4.69732 14.6455 7.00404 14.2652L7.21182 14.231C9.05892 13.9264 10.9411 13.9264 12.7882 14.231L12.996 14.2652C15.3027 14.6455 17 16.701 17 19.114C17 20.1571 16.1815 21.0026 15.1719 21.0026H4.82813C3.81848 21.0026 3 20.1571 3 19.114Z" stroke="#363853" strokeWidth="1.5"/>
        <path d="M14.0834 6.94008C14.0834 9.1147 12.2552 10.8776 10 10.8776C7.74486 10.8776 5.91669 9.1147 5.91669 6.94008C5.91669 4.76546 7.74486 3.00258 10 3.00258C12.2552 3.00258 14.0834 4.76546 14.0834 6.94008Z" stroke="#363853" strokeWidth="1.5"/>
    </svg>
);

export default NotificationsUser;