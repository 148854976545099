type Customers = {
    photo: string;
    customer: string;
    phone: string;
    date: string;
    orderId: number;
    status: "Завершено" | "Отменено" | "В ожидании";
}

export const TableHead = [
    "Фото",
    "Заказчик",
    "Телефон",
    "Дата и время",
    "Номер заказа",
    "Статус"
]

export const TableBody: Customers[]  = [
    {
        photo: "/table/photo.png",
        customer: "Иванов Иван Иванович",
        phone: "+7-999-999-9999",
        date: "23.08.2023, 16:25 - 19:00",
        orderId: 515175,
        status: "Завершено"
    },
    {
        photo: "/table/photo.png",
        customer: "Иванов Иван Иванович",
        phone: "+7-999-999-9999",
        date: "23.08.2023, 16:25 - 19:00",
        orderId: 515175,
        status: "Отменено"
    },
    {
        photo: "/table/photo.png",
        customer: "Иванов Иван Иванович",
        phone: "+7-999-999-9999",
        date: "23.08.2023, 16:25 - 19:00",
        orderId: 515175,
        status: "В ожидании"
    }
]