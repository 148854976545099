import styled from "styled-components";

export const StyledUploadFile = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const UploadFileButton = styled.div`
    color: #8181A5;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
    padding: 10px;
    cursor: pointer;
    border: 1px solid transparent;
    border-image: url("/button/button-border.svg") 1 stretch;
`;

export const UploadFileList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    .row {
        display: flex;
        gap: 10px;
        width: 100%;
        .fileName {
            width: 100%;
            border-radius: 4px;
            border: 1px solid #E1E4EA;
            padding: 0px 10px;
            height: 37px;
            display: flex;
            gap: 10px;
            align-items: center;
            .onlytitle {
                color: #8181A5;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-align: center;
                width: 100%;
            }
            .box {
                width: 25px;
                height: 25px;
                background: rgba(0, 0, 0, 0.20);
            }
            span {
                color: #282A42;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 100%;
            }
        }
        .buttonFile {
            width: 39px;
            height: 100%;
        }
    }
`;