import { DashboardLayout } from "app/layout";
import { Button } from "shared/components/Button";
import { card, dropdownPeriod, trafic } from "./statics";
import { Card } from "./components/Card";
import { OrdersTable } from "shared/components/OrdersTable";
import { useEffect, useState } from "react";
import { Row, Box, ModalWrapper, ButtonCalendar, WrapperDate } from "./style";
import { Dropdown } from "shared/components/Dropdown";
import { Modal } from "shared/components/Modal";
import { Input } from "shared/components/Input";
import moment from "moment";
import { Calendar } from "features/Calendar";

export const Dashboard = () => {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [open, setOpen] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState(dropdownPeriod[0]);

    const handleDateRangeSelect = (startDate: Date, endDate: Date) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const handleClose = () => {
        setOpen(!open);
    }

    useEffect(() => {
        if(selectedPeriod === "Свое") {
            setOpen(!open);
        }
    }, [selectedPeriod])
    
    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <div className="dashboard__header-title">Дашборд</div>
                <div className="dashboard__right-menu">
                    <Dropdown selected={selectedPeriod} setSelected={setSelectedPeriod} />
                </div>
            </div>
        } >
                <Row>
                    {card.map((item, index) => (
                        <Card 
                            key={index}
                            title={item.title}
                            amount={item.amount}
                            icon={item.icon}
                            procent={item.procent}
                        />
                    ))}
                </Row>
                <Row>
                    <div className="chart">
                        {/* <ChartLine /> */}
                    </div>
                    <Box>
                        <div className="title">Основной трафик</div>
                        <ul>
                            {trafic.map((item, index) => (
                                <li key={index}>
                                    <span>{item.name}</span>
                                    <span>{item.procent}%</span>
                                </li>
                            ))}
                        </ul>
                    </Box>
                </Row>
                <Row>
                    <OrdersTable />
                </Row>
            {/* <Modal
                isOpen={open}
                closeHandler={handleClose}
                title="Выбор периода"
            >
                <ModalWrapper>
                    <div className="modal-range-data">
                        <Input
                            name=""
                            className="modal-input"
                            placeholder="Дата начала"
                            label="Дата начала"
                            value={startDate ? startDate.toLocaleDateString() : ''}
                        />
                        <Input
                            name=""
                            className="modal-input"
                            placeholder="Дата конца"
                            label="Дата конца"
                            value={endDate ? endDate.toLocaleDateString() : ''}
                        />
                    </div>
                    <div>
                        <Calendar startDate={startDate} endDate={endDate} onDateRangeSelect={handleDateRangeSelect} />
                        {(startDate && startDate) &&
                            <WrapperDate>
                                {startDate && moment(startDate).format("DD.MM.YYYY")} - {endDate && moment(endDate).format("DD.MM.YYYY")}
                            </WrapperDate>
                        }
                        <ButtonCalendar isDisabled={(startDate && startDate) ? true : false}>Готово</ButtonCalendar>
                    </div>
                </ModalWrapper>
            </Modal> */}
        </DashboardLayout>
    );
}