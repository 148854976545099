import { DashboardLayout } from "app/layout"
import { Button } from "shared/components/Button"
import { StyledItem, TabsWrapper, WrapperAvatar } from "./style"
import { useState } from "react"
import { Tab, Tabs } from "shared/components/Tabs"
import { Avatar } from "shared/components/Avatar"
import { Input } from "shared/components/Input"
import { useNavigate, useParams } from "react-router-dom"
import { axiosApi } from "shared/api/axios";
import { useQuery } from "@tanstack/react-query"
import { StyledLine, WrapperFormProfile } from "shared/styles/GlobalStyle"
import { BackButton } from "shared/components/BackButton"
import { getEmployeesSingle } from "features/Employees/api/getEmployeesSingle"
import { UploadFile } from "entities/uploadFiles"

const columns = [
    {
        header: "Номер заказа",
        cell: (row: any) => {
            const value = row.renderValue();
            return value;
        },
        accessorKey: "numberOrder"
    },
    {
        header: "Дата использования",
        cell: (row: any) => {
            const value = row.renderValue();
            return value;
        },
        accessorKey: "dataUse"
    },
    {
        header: "Сумма потраченных денег",
        cell: (row: any) => {
            const value = row.renderValue();
            return `₽ ${value}`;
        },
        accessorKey: "summ"
    },
]

const file = [
    {
        id: 1,
        name: "Filename.jpg, 1MB"
    },
    {
        id: 2,
        name: "Filename.jpg, 1MB"
    },
    {
        id: 3,
        name: "Filename.jpg, 1MB"
    },
    {
        id: 4,
        name: "Filename.jpg, 1MB"
    }
]

export const EmployeesProfile = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isEditable, setIsEditable] = useState(false);
    const [editButtonText, setEditButtonText] = useState("Редактировать");
    
    const toggleEdit = () => {
        setIsEditable(!isEditable);
        setEditButtonText(isEditable ? "Редактировать" : "Сохранить");
    };

    const handleDeleteProfile = () => {
        axiosApi.delete(`/api/v1/users/user/${id}`);
        navigate('/clients')
    }

    const {isLoading, isError, data} = useQuery({
        queryKey: ["getProfile"],
        queryFn: () => getEmployeesSingle(Number(id))
    });

    const handleGoBack = () => {
        navigate('/employees/');
    }

    // utils getCoumnsWidth
    const columnWidths = ['40px', ...Array(columns.length).fill('1fr')];
    const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});

    const handleSelectionChange = (newSelectedRows: Record<string, boolean>) => {
        setSelectedRows(newSelectedRows);
    };

    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <StyledItem>
                    <BackButton 
                        text="Назад"
                        onClick={handleGoBack}
                    />
                    <div className="dashboard__header-title">Профиль</div>
                </StyledItem>
                <div className="dashboard__right-menu">
                    <Button themeType="delete" onClick={() => handleDeleteProfile()}>Удалить профиль</Button>
                </div>
            </div>
        }>
            <TabsWrapper>
                <Tabs>
                    <Tab label="Профиль">
                        <WrapperAvatar>
                            <Avatar photo="/table/photo.png" name="Иванов Иван Иванович" jobs="Администратор" />
                            <div className="editButton" onClick={toggleEdit}>{editButtonText}</div>
                        </WrapperAvatar>
                        <StyledLine margin="20px 0"/>
                        <WrapperFormProfile>
                            <div className="title">Общие данные</div>
                            <div className="container">
                                <div className="row">
                                    <Input
                                        name=""
                                        label="Телефон" 
                                        value="+7-999-999-9999"
                                        placeholder="Телефон"
                                        className="inputForm"
                                        disabled={!isEditable}
                                    />
                                    <Input 
                                        name=""
                                        label="Почта" 
                                        placeholder="Почта"
                                        value="Ivanov.Ivan@gmail.com"
                                        disabled={!isEditable}
                                        className="inputForm"
                                    />
                                    <Input 
                                        name=""
                                        label="Дата регистрации" 
                                        placeholder="Дата регистрации"
                                        value="18.08.2023"
                                        className="inputForm"
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                        </WrapperFormProfile>
                        <WrapperFormProfile>
                            <div className="title">Статистика</div>
                            <div className="container">
                                <div className="row">
                                    <Input
                                        name=""
                                        label="Заработано в сумме" 
                                        value="₽ 100,000"
                                        placeholder="Заработано в сумме"
                                        className="inputForm"
                                        disabled={!isEditable}
                                    />
                                    <Input 
                                        name=""
                                        label="Количество проданных допников" 
                                        placeholder="Количество проданных допников"
                                        value="3,000"
                                        disabled={!isEditable}
                                        className="inputForm"
                                    />
                                    <Input 
                                        name=""
                                        label="Сумма проданных допников" 
                                        placeholder="Сумма проданных допников"
                                        value="₽ 10,000"
                                        className="inputForm"
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                        </WrapperFormProfile>
                    </Tab>
                    <Tab label="Документы">
                        <WrapperFormProfile>
                            <div className="wrapperSerteficateHeader">
                                <div className="title">Документы</div>
                                <div className="editButton" onClick={toggleEdit}>{editButtonText}</div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <Input 
                                        name=""
                                        label="Серия и номер" 
                                        placeholder="Серия и номер"
                                        value="3784 148095"
                                        className="inputForm"
                                        disabled={!isEditable}
                                    />
                                    <Input 
                                        name=""
                                        label="Дата выдачи" 
                                        placeholder="Дата выдачи" 
                                        value="22.09.1993"
                                        className="inputForm"
                                        disabled={!isEditable}
                                    />
                                    <Input 
                                        name=""
                                        label="Код подразделения" 
                                        placeholder="Код подразделения" 
                                        value="770-251"
                                        className="inputForm"
                                        disabled={!isEditable}
                                    />
                                    <Input 
                                        name=""
                                        label="Кем выдан" 
                                        placeholder="Кем выдан" 
                                        value="ОУФМС МВД РОССИИ ПО МОССКОВСКОЙ ОБЛАСТИ"
                                        className="inputForm"
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div className="row">
                                    {/* <UploadFile file={file} disabled={isEditable} /> */}
                                </div>
                            </div>
                        </WrapperFormProfile>
                        <WrapperFormProfile>
                            <div className="wrapperSerteficateHeader">
                                <div className="title">Место прописки</div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <Input 
                                        name=""
                                        label="Город" 
                                        placeholder="Город" 
                                        value="Москва"
                                        disabled={!isEditable}
                                        className="inputForm"
                                    />
                                    <Input 
                                        name=""
                                        label="Улица" 
                                        placeholder="Улица" 
                                        value="Пушкина"
                                        disabled={!isEditable}
                                        className="inputForm"
                                    />
                                    <Input 
                                        name=""
                                        label="Дом" 
                                        placeholder="Дом" 
                                        value="10"
                                        disabled={!isEditable}
                                        className="inputForm"
                                    />
                                    <Input 
                                        name=""
                                        label="Квартира" 
                                        placeholder="Квартира"
                                        value="95"
                                        disabled={!isEditable}
                                        className="inputForm"
                                    />
                                    <Input 
                                        name=""
                                        label="Индекс" 
                                        placeholder="Индекс"
                                        value="440231"
                                        disabled={!isEditable}
                                        className="inputForm"
                                    />
                                </div>
                            </div>
                        </WrapperFormProfile>
                    </Tab>
                </Tabs>
            </TabsWrapper>
        </DashboardLayout>
    )
}