export const notifications = [
    {
        name: "Новое бронирование",
        location: "Зал",
        contact: "Имя/компания, +79232342341",
        comments: "Комментарий",
        paymentStatus: "Статус оплаты",
        paymentAmount: "100₽",
        time: "00:00 - 00:00"
    },
    {
        name: "Новое бронирование",
        location: "Зал",
        contact: "Имя/компания, +79232342341",
        comments: "Комментарий",
        paymentStatus: "Статус оплаты",
        paymentAmount: "100₽",
        time: "00:00 - 00:00"
    },
    {
        name: "Новое бронирование",
        location: "Зал",
        contact: "Имя/компания, +79232342341",
        comments: "Комментарий",
        paymentStatus: "Статус оплаты",
        paymentAmount: "100₽",
        time: "00:00 - 00:00"
    },
    {
        name: "Новое бронирование",
        location: "Зал",
        contact: "Имя/компания, +79232342341",
        comments: "Комментарий",
        paymentStatus: "Статус оплаты",
        paymentAmount: "100₽",
        time: "00:00 - 00:00"
    }
];


export const booking = [
    {
        name: "Название зала",
        contact: "Имя/компания, +79232342341",
        paymentStatus: "Оплачено",
        paymentAmount: "100₽",
        time: "00:00 - 00:00"
    },
    {
        name: "Название зала",
        contact: "Имя/компания, +79232342341",
        paymentStatus: "Не оплачено",
        paymentAmount: "100₽",
        time: "00:00 - 00:00"
    },
    {
        name: "Название зала",
        contact: "Имя/компания, +79232342341",
        paymentStatus: "Частично оплачено",
        paymentAmount: "100₽",
        time: "00:00 - 00:00"
    },
    {
        name: "Название зала",
        contact: "Имя/компания, +79232342341",
        paymentStatus: "Частично оплачено",
        paymentAmount: "100₽",
        time: "00:00 - 00:00"
    },
];