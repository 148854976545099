import styled from "styled-components";

export const AccordionWrapper = styled.div`
    width: 100%;
    margin-bottom: 16px;
    &:last-child {
        margin-bottom: 0;
    }
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        .title {
            color: #282A42;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }
        .toggle-arrow {
            &-item {

            }
        }
    }
    .content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
        .row {
            display: flex;
            align-items: end;
            gap: 10px;
        }
        label {
            font-size: 12px;
        }
    }
`