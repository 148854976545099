import axios from 'axios';
import { getUploadUrls } from 'shared/hooks/useUploadFile';

export const uploadFile = async (file: File): Promise<{ upload_url: string, hash_name: string }> => {
    const uploadUrls = await getUploadUrls({ filenames: [file.name] });
    if (uploadUrls.length === 0) {
        throw new Error("Не удалось получить URL для загрузки файла");
    }

    const { upload_url, hash_name } = uploadUrls[0];

    await axios.put(upload_url, file, {
        headers: {
            'Content-Type': file.type
        }
    });

    return { upload_url, hash_name };
};

export const uploadMultipleFiles = async (files: File[]): Promise<{ upload_url: string, hash_name: string }[]> => {
    const uploadUrls = await getUploadUrls({ filenames: files.map(file => file.name) });
    if (uploadUrls.length === 0) {
        throw new Error("Не удалось получить URL для загрузки файлов");
    }

    const uploadPromises = uploadUrls.map(({ upload_url, hash_name }, index) => {
        const file = files[index];
        return axios.put(upload_url, file, {
            headers: {
                'Content-Type': file.type
            }
        }).then(() => {
            return { upload_url, hash_name };
        });
    });

    return Promise.all(uploadPromises);
};
