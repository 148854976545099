import styled from "styled-components";

export const StyledCreateCertificate = styled.div`
    .container {
        margin-bottom: 10px;
        border-bottom: 1px solid #8181A5;
        padding-bottom: 20px;
    }
    .wrapperSerteficate {
        .serteficateNumber {
            flex: none!important;
            max-width: 101px
        }
        .serteficateDate {
            flex: none!important;
            max-width: 95px;
        }
        .serteficateSumm {
            flex: none!important;
            max-width: 104px;
        }
    }
    .editButton {
        display: flex;
        gap: 8px;
        align-items: center;
        .icon-add {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #5E81F4;
            border-radius: 8px;
        }
    }
`