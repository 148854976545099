import { DashboardLayout } from "app/layout"
import { EmployeesTable } from "features/Employees"
import { useMemo, useState } from "react"
import { Button } from "shared/components/Button"
import { Icon } from "shared/components/Icons"
import { TabLink } from "shared/components/TabLink"
import { listPages } from "shared/consts/linksControlPages"

export const EmployeesList = () => {
    const [selectedRows, setSelectedRows] = useState<number[]>([]);

    const hasSelectedRows = useMemo(() => {
        return selectedRows.length > 0;
    }, [selectedRows]);

    const handleDelete = async (selectedRows: number[]) => {
        console.log(selectedRows);
    };

    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <div className="dashboard__header-title">Управление</div>
                <TabLink list={listPages} />
                <Button beforeIcon="search"></Button>
                {!hasSelectedRows ? (
                    <div className="dashboard__right-menu" style={{marginLeft: "inherit"}}>
                        
                        <Button>+Сотрудник</Button>
                    </div>
                ) : (
                    <Button themeType="delete" onClick={() => handleDelete(selectedRows)}>Удалить клиента</Button>
                )}
            </div>
        }>
            <EmployeesTable 
                onSelectedRowsChange={setSelectedRows}
            />
        </DashboardLayout>
    )
}