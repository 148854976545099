import { Icon } from "shared/components/Icons";
import styled from "styled-components";

interface IPropsCard {
    title: string;
    amount: string;
    icon: string;
    procent: number;
}

const StyledCard = styled.div`
    border-radius: 8px;
    background: #FAFCFF;
    padding: 16px 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15.5px;
    .card {
        &-row {
            display: flex;
            gap: 10px;
            align-items: center;
            div:first-child {
                width: 24px;
                height: 24px;
            }
            svg {
                width: 24px;
                height: 24px;
            }
        }
        &-title {
            color: #282A42;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
        }
        &-amount {
            color: #282A42;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 36px;
        }
        &-procent {
            border-radius: 19px;
            display: flex;
            padding: 7px 12px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            width: fit-content;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            &--red {
                background: #F5DCDC;
                color: #D14F4F;
            }
            &--green {
                background: #DCF5E8;
                color: #4FD18B;
            }
        }
    }
`;

export const Card = ({title, amount, icon, procent}: IPropsCard) => {
    return (
        <StyledCard>
            <div className="card-row">
                <Icon name={icon}/>
                <div className="card-title">{title}</div>
            </div>
            <div className="card-amount">{amount}</div>
            <div className={`card-procent ${procent < 0 ? "card-procent--red" : "card-procent--green"}`}>
                {procent}%
            </div>
        </StyledCard>
    );
};