import styled from "styled-components";

export const StyledWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    background: #F4F3FA;
    height: calc(100vh - 20px);
    padding: 10px 0;
    justify-content: space-between;
`;

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 16px;
    background: #fff;
    width: calc(100% - 20px);
    height: calc(100% - 60px);
    padding: 10px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 2px; 
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(94, 129, 244, 0.50);
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(94, 129, 244, 0.70);
    }
`;

export const StyledHeader = styled.div`
    border-radius: 16px;
    background: #fff;
    width: 100%;
    height: 60px;
    .dashboard {
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;
            height: 100%;
            &--start {
                justify-content: flex-start;
                gap: 30px;
            }
            &-title {
                color: #282A42;
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }
        }
        &__right-menu {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-left: auto;
            button {
                height: 40px;
            }
        }
    }
`;