import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Providers } from "./providers"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SignInPages } from "pages/sign-in";
import { PrivateRoute } from "./routers/privateRouter";
import { Dashboard } from "pages/dashboard";
import { Provider } from "react-redux";
import { store } from "shared/redux/store";
import { useEffect } from "react";
import { Clients } from "pages/clients";
import { CreateProfile } from "pages/clientsCreate";
import { Profile } from "pages/clientsProfile";
import { Booking } from "pages/booking";
import { EquipmentCreate } from "pages/control/equipment/equipmentCreate";
import { EquipmentProfile } from "pages/control/equipment/profile";
import { EquipmentList } from "pages/control/equipment";
import { EmployeesCreate } from "pages/control/employees/employeesCreate";
import { checkAuth } from "features/auth/model/authSlice";
import { Finance } from "pages/finance";
import { Setting } from "pages/setting";
import { Archive } from "pages/archive";
import { CalendarDayPage } from "pages/calendarDay";
import { CalendarWeekPage } from "pages/calendarWeek";
import { CalendarMonthPage } from "pages/calendarMonth";
import { EmployeesList } from "pages/EmployeesList";
import { HallsList } from "pages/HallsList";
import { EmployeesProfile } from "pages/EmployeesProfile";

function App() {
    const queryClient = new QueryClient()
    
    return (
        <QueryClientProvider client={queryClient}>
                <Providers>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/auth/sign-in" element={<SignInPages />} />
                            <Route path="/" element={<PrivateRoute />}>
                            
                                <Route path="/" element={<Dashboard />} />
                                
                                {/* Календарь */}
                                <Route path="/calendar/day" element={<CalendarDayPage />} />
                                <Route path="/calendar/week" element={<CalendarWeekPage />} />
                                <Route path="/calendar/month" element={<CalendarMonthPage />} />

                                {/* Клиенты */}
                                <Route path="/clients" element={<Clients />} />
                                <Route path="/clients/new" element={<CreateProfile />} />
                                <Route path="/clients/profile/:id" element={<Profile />} />
                                <Route path="/clients/booking" element={<Booking />} />

                                 {/* Залы */}
                                <Route path="/halls/" element={<HallsList />} />

                                {/* Оборудование */}
                                <Route path="/equipment/" element={<EquipmentList />} />
                                <Route path="/equipment/create" element={<EquipmentCreate />} />
                                <Route path="/equipment/profile/:id" element={<EquipmentProfile />} />

                                {/* Сотрудники */}
                                <Route path="/employees/" element={<EmployeesList />} />
                                <Route path="/employees/create" element={<EmployeesCreate />} />
                                <Route path="/employees/profile/:id" element={<EmployeesProfile />} />

                                {/* Финансы и */}
                                <Route path="/finance" element={<Finance />} />

                                {/* Настройки */}
                                <Route path="/setting" element={<Setting />} />

                                {/* Архив */}
                                <Route path="/archive" element={<Archive />} />
                            </Route>
                            <Route path="*" element={<div>404... not found </div>} />
                        </Routes>
                    </BrowserRouter>
                </Providers>
        </QueryClientProvider>
    )
}

const AppWrapper = () => {
    useEffect(() => {
        store.dispatch(checkAuth());
    }, []);
    return (
        <Provider store={store}>
            <App />
        </Provider>
    )
}

export default AppWrapper