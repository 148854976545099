export const trafic = [
    {
        name: "Google",
        procent: "13"
    },
    {
        name: "YouTube",
        procent: "13"
    },
    {
        name: "Instagram",
        procent: "13"
    },
    {
        name: "Pinterest",
        procent: "13"
    },
    {
        name: "Facebook",
        procent: "13"
    },
    {
        name: "Twitter",
        procent: "13"
    },
    {
        name: "Google",
        procent: "13"
    },
];

export const card = [
    {
        icon: "add",
        title: "Выручка",
        amount: "₽ 1,250,256",
        procent: 11,
    },
    {
        icon: "remove",
        title: "Отменено броней",
        amount: "2",
        procent: 11,
    },
    {
        icon: "rotate",
        title: "Возвраты",
        amount: "₽ 7,324",
        procent: -8,
    },
    {
        icon: "chart",
        title: "Количествоо заказов",
        amount: "124",
        procent: -8,
    },
];

export const dropdownPeriod = ["Сегодня", "Неделя", "Месяц", "Год", "Свое"];