import { TableBody, TableHead } from "./statics";
import styled from 'styled-components';

const StyledTable = styled.div`
  width: 100%;
`;

const StyledHead = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 120px repeat(5, 1fr);
  border-radius: 8px;
  background: #F4F7FF;
  div {
    text-align: left;
    padding: 20px;
    color: #8181A5;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
  }
`;

const StyledBody = styled.div`
  width: 100%;
  .row {
    display: grid;
    grid-template-columns: 120px repeat(5, 1fr);
    margin-top: 10px;
    border-radius: 8px;
    border: 1px solid #E1E4EA;
    background: white;

    &.table-cell-photo {
      padding-left: 30px;
    }

    .cell {
      display: flex;
      align-items: center;
      padding: 6px 20px;
    }

    img {
      border-radius: 50px;
    }

    span {
      color: #1C1D21;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }

    .statusWrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .status {
        padding: 6px 10px;
        text-align: center;
        border-radius: 30px;
      }
    }
  }
`;

const getColorForStatus = (status: string) => {
  switch (status) {
    case 'Завершено':
      return {
        background: "#D9F2DD",
        color: "#359742"
      };
    case 'Отменено':
      return {
        background: "#FFE5E5",
        color: "#F66"
      };
    case 'В ожидании':
      return {
        background: "#FFF5CC",
        color: "#CCA300"
      };
    default:
      return {
        background: "gray",
        color: "white"
      };
  }
};

export const OrdersTable = () => {
  return (
    <StyledTable>
      <StyledHead>
        {TableHead.map((header, index) => (
          <div style={{ textAlign: (index === 4 || index === 5) ? 'center' : 'left' }} key={index}>
            {header}
          </div>
        ))}
      </StyledHead>
      <StyledBody>
        {TableBody.map((item, index) => (
          <div className="row" key={index}>
            <div className="cell table-cell-photo">
              <img src={item.photo} alt={item.customer} width={48} height={48} />
            </div>
            <div className="cell"><span>{item.customer}</span></div>
            <div className="cell"><span>{item.phone}</span></div>
            <div className="cell"><span>{item.date}</span></div>
            <div className="cell"><span style={{margin: "0 auto"}}>{item.orderId}</span></div>
            <div className="cell">
              <div className="statusWrapper" style={{ margin: "0 auto"}}>
                <div className="status" style={getColorForStatus(item.status)}>{item.status}</div>
              </div>
            </div>
          </div>
        ))}
      </StyledBody>
    </StyledTable>
  );
}
