export const personalInitialValues = {
    type: 0,
    phone: '',
    email: '',
    profile_photo_hash_name: '',
    document_photos_hash_names: [],
    additional_data: {
        documents: {
            serial: '',
            division_code: '',
            issued_by: '',
            issue_date: '',
            city: '',
            street: '',
            house_number: '',
            apartment_number: '',
            index: ''
        },
    },
    contact: {
        surname: '',
        first_name: '',
        patronymic: '',
        phone: '',
        email: '',
    },
    discount: 0,
};