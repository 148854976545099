import { useState } from "react";
import { ConfirmCodeForm } from "./components/ConfirmCodeForm";
import { ForgotPasswordForm } from "./components/ForgotPasswordForm";
import { LoginForm } from "./components/LoginForm";

export const SignIn = () => {
  const [isForgotPassword, setIsForgotPassword] = useState<boolean>(false);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);

  if(isConfirm) {
    return <ConfirmCodeForm setIsConfirm={setIsConfirm} />;
  }

  if(isForgotPassword) {
    return <ForgotPasswordForm setIsForgotPassword={setIsForgotPassword} />;
  }
  return <LoginForm setIsForgotPassword={setIsForgotPassword} />;
}