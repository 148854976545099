export const columns = [
    {
        header: "Фото",
        cell: (row: any) => {
            const value = row.renderValue();
            return (
                <div className="bodyCellImg">
                    {typeof value === 'object' ? <div className="bodyCellImgBg"></div> : <img src={value} alt={"Фото"} />}
                </div>
            )
        },
        accessorKey: "user_ad_personal_info.profile_photo"
    },
    {
        header: "Имя",
        cell: (row: any) => {
            const value = row.renderValue();
            return typeof value === 'object' ? "ИмяСтатик" : row.renderValue()
        },
        accessorKey: "user_ad_personal_info.first_name"
    },
    {
        header: "Телефон",
        cell: (row: any) => {
            return row.renderValue()
        },
        accessorKey: "phone"
    },
    {
        header: "Почта",
        cell: (row: any) => {
            return row.renderValue()
        },
        accessorKey: "email"
    },
    {
        header: "Роль",
        cell: (row: any) => {
            return row.renderValue()
        },
        accessorKey: "role.name"
    },
    {
        header: "Заработано",
        cell: (row: any) => {
            return <div>0 ₽</div>
        },
        accessorKey: "sum"
    },
]