import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NotificationState {
    message: string | null;
    type: 'success' | 'error' | 'info' | null;
    errMessage?: string | null;
}

const initialState: NotificationState = {
    message: null,
    type: null,
    errMessage: null
};

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        showNotification: (state, action: PayloadAction<NotificationState>) => {
            state.message = action.payload.message;
            state.type = action.payload.type;
            state.errMessage = action.payload.errMessage
        },
        clearNotification: state => {
            state.message = null;
            state.type = null;
        },
    },
});

export const { showNotification, clearNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
