import { motion } from "framer-motion";
import styled from "styled-components";

export const RightMenu = styled.div`
    display: flex;
    gap: 10px;
`

export const TabsWrapper = styled.div`
    height: 100%;
    .tabContainer {
        position: relative;
        .tabHeaders {
            background: #F4F3FA;
            border-radius: 8px;
            gap: 6px;
            width: 100%;
            max-width: 382px;
            .tabHeader {
                color: #8181A5;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding: 11px 25px;
                &.active {
                    background: inherit;
                    color: #282A42;
                }
                span {
                    position: relative;
                    z-index: 99;
                }
            }
            .underline {
                height: 39px;
                bottom: 0;
                background-color: #FFF;
                border-radius: 8px;
                border: 1px solid #5E81F4;
            }
        }
    }
    .equipmentTable {
        .wrapperCell {
            .wrapperCellTitle {
                color: #1C1D21;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
            }
            .wrapperCellDesc {
                color: #8181A5;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }
        }
    }
`;

export const WrapperSearch = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    max-width: 256px;
    height: 39px;
    .styledWrapperInput {
        .styledBeforeIcon {
            height: 16px;
        }
        .styledInput {
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 100%;
            color: #8181A5;
            padding-left: 30px;
        }
    }
`;

export const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`;

export const LinkHeaders = styled.div`
    display: flex;
    position: relative;
`;

export const LinkHeader = styled.div<{ isActive: boolean }>`
    display: flex;
    color: #282A42;
    color: ${(props) => (props.isActive ? "#282A42" : "#8181A5")};;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    cursor: pointer;
    justify-content: center;
    width: 100%;
`;

export const Underline = styled(motion.div)`
    position: absolute;
    bottom: -5px;
    height: 1px;
    background: #5E81F4;
`;

export const ModalWrapper = styled.div`
    width: 100%;
    max-width: 367px;
    p {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 200;
        line-height: 20px;
    }
    .row {
        display: flex;
        gap: 10px;
        .ModalWrapperButtonYes {
            background: none;
            border: 1px solid #5E81F4;
            span {
                color: #5E81F4;
            }
        }
        button {
            width: 100%;
        }
    }
`