export const theme = {
    background: {
        main: "#F4F3FA", // цвет заднего фона,
        white: "#FFF", // цвет блоков
    },
    colors: {
        text: '#212529',
    },
    input: {
        label: "#8181A5",
        text: "black",
        placeholder: "#CDCDDF",
    },
    borderRadius: '16px',
};