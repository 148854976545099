import styled from "styled-components";

export const StyledUploadAvatar = styled.div`
    display: flex;
    gap: 30px;
    align-items: center;
    .avatar {
        width: 70px;
        height: 70px;
        border-radius: 35px;
        overflow: hidden;
        .circle {
            width: 100%;
            height: 100%;
            background:rgba(0, 0, 0, 0.20);
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .uploadAvatarButton {
        label {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            span {
                color: #8181A5;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }
        }
    }
`