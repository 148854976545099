import moment from "moment";

export const columnsOrg = [
    {
        header: "",
        id: "photo",
        cell: (row: any) => {
            const value = row.renderValue();
            return (
                <div className="bodyCellImg">
                    {value ? <img src={value} alt={"Фото"} /> : <div className="bodyCellImgBg"></div>}
                </div>
            )
        },
        accessorKey: "profile_photo.read_url"
    },
    {
        header: "Наименование",
        cell: (row: any) => {
            return row.renderValue();
        },
        accessorFn: (row: any) => row?.additional_data?.documents?.organization_name || [],
        id: "organization_name"
    },
    {
        header: "Телефон",
        cell: (row: any) => {
            return row.renderValue();
        },
        accessorKey: "phone"
    },
    {
        header: "Почта",
        cell: (row: any) => {
            return row.renderValue();
        },
        accessorKey: "email"
    },
    {
        header: "Количество часов брони",
        cell: (row: any) => {
            return row.renderValue() ? row.renderValue() : 0;
        },
        accessorKey: "booking_hours",
        id: "booking_hours"
    },
    {
        header: "Дата регистрации",
        cell: (row: any) => {
            return moment(row.renderValue()).format("DD.MM.YYYY")
        },
        accessorKey: "created_at",
        id: "created_at"
    },
]