export const orgInitialValues = {
    type: 1,
    phone: '',
    email: '',
    profile_photo_hash_name: '',
    document_photos_hash_names: [],
    additional_data: {
        documents: {
            organization_name: '',
            inn: '',
            bank_name: '',
            bik: '',
            kpp: '',
            checking_account: '',
            legal_address: '',
            registration_date: '',
            correspondent_account: '',
        },
    },
    contact: {
        surname: '',
        first_name: '',
        patronymic: '',
        phone: '',
        email: '',
    },
    discount: 0,
};