import { DashboardLayout } from "app/layout"
import { useNavigate } from "react-router-dom"
import { Button } from "shared/components/Button"
import { ModalWrapper, TabsWrapper, WrapperSearch } from "./style"
import { Tab, Tabs } from "shared/components/Tabs"
import { Input } from "shared/components/Input"
import { Table } from "shared/components/Table"
import { useMemo, useState } from "react"
import { Modal } from "shared/components/Modal"
import { TabLink } from "shared/components/TabLink"
import { listPages } from "shared/consts/linksControlPages"

const statics = [
    {
        photo: "/lampa.png",
        name: "Лампа",
        stock: "1",
        order: "Помещение",
        sum: "30 000",
        price: "300",
        comment: "В наличии",
    },
    {
        photo: "/lampa.png",
        name: "Лампа",
        stock: "1",
        order: "Помещение",
        sum: "30 000",
        price: "300",
        comment: "В наличии",
    },
    {
        photo: "/lampa.png",
        name: "Лампа",
        stock: "1",
        order: "Помещение",
        sum: "30 000",
        price: "300",
        comment: "В наличии",
    }
];

const columns = [
    {
        header: "Фото",
        cell: (row: any) => {
            const value = row.renderValue();
            return (
                <div className="bodyCellImg">
                    {typeof value === 'object' ? <div className="bodyCellImgBg"></div> : <img src={value} alt={"Фото"} />}
                </div>
            )
        },
        accessorKey: "photo"
    },
    {
        header: "Наименование",
        cell: (row: any) => {
            const value = row.renderValue();
            return (
                typeof value === 'object' ? "ИмяСтатик" : 
                <div className="wrapperCell">
                    <div className="wrapperCellTitle" style={{fontWeight: 700}}>{value}</div>
                    <div className="wrapperCellDesc">#1432634</div>
                </div>
            )
        },
        accessorKey: "name"
    },
    {
        header: "Наличие",
        cell: (row: any) => {
            return (
                <div className="wrapperCell">
                    <div className="wrapperCellTitle">{row.renderValue()}</div>
                    <div className="wrapperCellDesc">В наличиии</div>
                </div>
            )
        },
        accessorKey: "stock"
    },
    {
        header: "Распродано",
        cell: (row: any) => {
            return (
                <div className="wrapperCell">
                    <div className="wrapperCellTitle">{row.renderValue()}</div>
                    <div className="wrapperCellDesc">время</div>
                </div>
            )
        },
        accessorKey: "order"
    },
    {
        header: "Заработано",
        cell: (row: any) => {
            return (
                <div className="wrapperCell">
                    <div className="wrapperCellTitle">₽ {row.renderValue()}</div>
                    <div className="wrapperCellDesc">Заработано</div>
                </div>
            )
        },
        accessorKey: "sum"
    },
    {
        header: "Цена",
        cell: (row: any) => {
            return (
                <div className="wrapperCell">
                    <div className="wrapperCellTitle">₽ {row.renderValue()}</div>
                    <div className="wrapperCellDesc">Цена</div>
                </div>
            )
        },
        accessorKey: "price"
    },
    {
        header: "Комментарий",
        cell: (row: any) => {
            return (
                <div className="wrapperCell">
                    <div className="wrapperCellDesc">В наличиии</div>
                </div>
            )
        },
        accessorKey: "comment"
    },
]

interface Documents {
    serial: number;
    division_code: string;
    issued_by: string;
    issue_date: string;
    registration_address: string;
    registration_number: string;
}

interface AdditionalData {
    documents: Documents;
}

interface Counterparty {
    id: number;
    type: number;
    phone: string;
    email: string;
    additional_data: AdditionalData;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
}

interface CounterpartiesResponse {
    counterparties: Counterparty[];
}

export const EquipmentList = () => {
    const [open, setOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();

    const handleClick = (to: string) => {
        navigate(to);
    }

    const handleClose = () => {
        setOpen(!open);
    }

    const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});

    const handleSelectionChange = (newSelectedRows: Record<string, boolean>) => {
        setSelectedRows(newSelectedRows);
    };

    const hasSelectedRows = useMemo(() => {
        return Object.values(selectedRows).some(value => value);
    }, [selectedRows]);
    
    const columnWidths = ['40px', '120px', ...Array(columns.length - 1).fill('1fr')];

    // const getClients = async (): Promise<Counterparty[]> => {
    //     try {
    //         const response = await axiosApi.get<CounterpartiesResponse>("/api/v1/counterparties/counterparty/");
    //         return response.data.counterparties;
    //     } catch (err) {
    //         console.error("Error fetching clients", err);
    //         return [];
    //     }
    // }

    // const { isLoading, isError, data } = useQuery({
    //     queryKey: ["getClients",], 
    //     queryFn: getClients,
    // });

    const handleDelete = (selectedRows: object) => {
        setOpen(!open);
        console.log(selectedRows);
    }

    const handleTabChange = (index: number) => {
        setActiveTab(index);
    };

    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <div className="dashboard__header-title">Управление</div>
                <TabLink list={listPages} />
                <div className="dashboard__right-menu" style={{marginLeft: "inherit"}}>
                    {hasSelectedRows ? (
                        <>
                            <Button themeType="delete" onClick={() => handleDelete(selectedRows)}>Удалить</Button>
                        </>
                    ) : (
                        <>
                            <Button onClick={() => handleClick("/clients/new")}>+Добавить оборудование</Button>
                        </>
                    )}
                </div>
            </div>
        }>
            <TabsWrapper>
                <Tabs onTabChange={handleTabChange}>
                    <Tab label="Расходные материалы">
                        <WrapperSearch>
                            <Input name="" $beforeIcon="search" placeholder="Поиск" />
                        </WrapperSearch>
                        {/* {isLoading && <div>Загрузка...</div>}
                        {isError && <div>Ошибка загрузки данных</div>} */}
                        {/* {!isLoading && data && ( */}
                            <Table 
                                className="equipmentTable" 
                                data={statics as any}
                                columns={columns} 
                                columnWidths={columnWidths}
                                // onSelectionChange={handleSelectionChange}
                                navigation="/control/equipment/profile"
                            />
                        {/* )} */}
                    </Tab>
                    <Tab label="Оборудование">
                        <WrapperSearch>
                            <Input name="" $beforeIcon="search" placeholder="Поиск" />
                        </WrapperSearch>
                            <Table 
                                className="equipmentTable" 
                                data={statics as any}
                                columns={columns} 
                                columnWidths={columnWidths}
                                // onSelectionChange={handleSelectionChange}
                                navigation="/control/equipment/profile"
                            />
                        {/* {isLoading && <div>Загрузка...</div>}
                        {isError && <div>Ошибка загрузки данных</div>}
                        {!isLoading && data && (
                            <Table 
                                className="clientsTable" 
                                data={data}
                                columns={columns} 
                                columnWidths={columnWidths}
                                onSelectionChange={handleSelectionChange}
                                navigation="/clients/profile"
                            />
                        )} */}
                    </Tab>          
                </Tabs>
            </TabsWrapper>
            {/* <Modal
                isOpen={open}
                closeHandler={handleClose}
                title="Удалить оборудование?"
            >
                <ModalWrapper>
                    <p>Вы уверены, что хотите удалить выбранное оборудование? После нажатия на кнопку “Подтвердить”, они будут удалены.</p>
                    <div className="row">
                        <Button onClick={handleDelete} className="ModalWrapperButtonYes">Да</Button>
                        <Button onClick={handleClose}>Нет</Button>
                    </div>
                </ModalWrapper>
            </Modal> */}
        </DashboardLayout>
    )
}