import styled from "styled-components";

export const Row = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
    .chart {
        width: 100%;
        border-radius: 8px;
        background: #FAFCFF;
    }
`;

export const Box = styled.div` // переделать под card
    border-radius: 8px;
    background: #FAFCFF;
    padding: 24px;
    width: 100%;
    max-width: 272px;
    .title {
        color: #1C1C1C;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 24px;
    }
    ul {
        padding: 0;
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24.4px;
            &:last-child {
                margin-bottom: 8px;
            }
            span {
                color: #1C1C1C;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
            }
        }
    }
`;

export const ModalWrapper = styled.div`
    .modal-range-data {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 20px;
        .modal-input {
            width: 100%;
        }
        label {
            font-size: 12px;
        }
    }
`;

export const ButtonCalendar = styled.div<{ isDisabled: boolean }>`
    margin-top: 10px;
    border-radius: 8px;
    background: ${props => (props.isDisabled ? '#5E81F4' : 'rgba(94, 129, 244, 0.50)')};;
    padding: 11px 0;
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    color: #fff;
`;

export const WrapperDate = styled.div`
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #282A42;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 100%;
    justify-content: center;
`