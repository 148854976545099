import { axiosApi } from "shared/api/axios";
import { ProfileTypes } from "../types/ProfileTypes";
import { showNotification } from "shared/redux/slice/notificationSlice";

export const handleEditProfile = async (
    values: ProfileTypes,
    refetch: () => void,
    setIsEditable: any,
    setEditButtonText: any,
    dispatch: any,
    id: any,
) => {
    try {
        const response = await axiosApi.patch(`/api/v1/counterparties/counterparty/${id}`, values);
        if(response.status !== 204) {
            setIsEditable(true);
            setEditButtonText("Сохранить");
        } else {
            refetch();
            setIsEditable(false);
            setEditButtonText("Редактировать");
        }
    } catch (err) {
        dispatch(showNotification({message: "Ошибка", type: "error"}))
        setIsEditable(true);
        setEditButtonText("Сохранить");
        console.error("Ошибка сохранения данных:", err);
    }
};