export const PlaceData = [
    [
        { 
            name: "additional_data.documents.city", 
            label: "Город", 
            placeholder: "Москва", 
            type: "text",
        },
        { 
            name: "additional_data.documents.street", 
            label: "Улица", 
            placeholder: "Пушкина", 
            type: "text",
        },
        { 
            name: "additional_data.documents.house_number", 
            label: "Дом", 
            placeholder: "10", 
            type: "text"
        },
        { 
            name: "additional_data.documents.apartment_number", 
            label: "Квартира", 
            placeholder: "95",
            type: "text",
            maskConfig: {
                mask: Number
            }
        },
        { 
            name: "additional_data.documents.index", 
            label: "Индекс", 
            placeholder: "440231", 
            type: "text",
            maskConfig: {
                mask: Number
            }
        },
    ],
];