import styled from "styled-components";

export const WrapperBackButton = styled.div<{ disabled: boolean, reverseorder: boolean }>`
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    flex-direction: ${({ reverseorder }) => (reverseorder ? 'row-reverse' : 'row')};
`;

export const BackButtonIcon = styled.div<{ iconColor?: string }>`
    width: 39px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 0.848px solid #E1E4EA;
    background: ${({ iconColor }) => (iconColor ? iconColor : '#fff')};
`;

export const BackButtonText = styled.div`
    color: #8181A5;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.06px;
`;