import { DashboardLayout } from "app/layout";
import { Button } from "shared/components/Button";
import { TabLink } from "shared/components/TabLink";
import { linksCalendar } from "shared/consts/linksCalendar";
import { PeriodSelector } from "shared/components/PeriodSelector";
import moment, { Moment } from "moment";
import { forwardRef, useState } from "react";
import { ReactBigCalendar } from 'features/ReactBigCalendar';

export const CalendarDayPage = () => {
    const [currentDay, setCurrentDay] = useState<Moment>(moment());

    const handleDateChange = (date: Moment) => {
        setCurrentDay(date);
    };

    const views = {
        day: true
    };

    const resources = [
        { id: 'hall1', title: 'Зал 1' },
        { id: 'hall2', title: 'Зал 2' },
        { id: 'hall3', title: 'Зал 3' },
        { id: 'hall4', title: 'Зал 4' },
        { id: 'hall5', title: 'Зал 5' },
        { id: 'hall6', title: 'Зал 6' },
        { id: 'hall7', title: 'Зал 7' },
    ];

    const customComp = <div>Hello, hh</div>;

    const events = [
        {
            title: customComp,
            phone: "+7-999-999-99-99",
            status: "paid",
            start: moment().toDate(),
            end: moment().add(1, 'hour').toDate(),
            resourceId: 'hall1'
        },
        {
            title: "ФИ клиента/компания",
            phone: "+7-999-999-99-99",
            status: "partially_paid",
            start: moment().add(1, 'hours').toDate(),
            end: moment().add(3, 'hours').toDate(),
            resourceId: 'hall2'
        },
        {
            title: "ФИ клиента/компания",
            phone: "+7-999-999-99-99",
            status: "unpaid",
            start: moment().add(1, 'day').toDate(),
            end: moment().add(1, 'day').toDate(),
            resourceId: 'hall3'
        }
    ];

    const DayColumnWrapper = ({ children, className, style, innerRef }: any) => {
        return (
            <div className={`day-header ${className}`} style={style} ref={innerRef}>
                {children}
            </div>
        );
    };

    const dayCol = forwardRef((dayColumnWrapperProps, ref) => {
        return (
            <DayColumnWrapper {...dayColumnWrapperProps} innerRef={ref} />
        )
    })

    dayCol.displayName = "dayCol";


    const eventsWrapper = ({ event, children, className, style, innerRef }: any) => {
        return (
            <>{children}</>
            // <div className="eventsCustom eventsCustom__day" style={{
            //     position: "absolute",
            //     top: ${style.top}%,
            //     width: "100%",
            //     height: ${style.height}%
            // }} ref={innerRef}>
            //     <div className="eventsCustom__day-info">{event.title}</div>
            //     <div className="eventsCustom__day-info">{event.phone}</div>
            //     {/* {children} */}
            // </div>
        );
    };

    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <TabLink list={linksCalendar} />
                <PeriodSelector period="day" onDateChange={handleDateChange} />
                <div className="dashboard__right-menu">
                    <Button>+ Новая бронь</Button>
                </div>
            </div>
        }>
            <ReactBigCalendar
                views={views}
                defaultView="day"
                min={new Date(new Date().setHours(9, 0, 0, 0))}
                max={new Date(new Date().setHours(17, 0, 0, 0))}
                currentDay={currentDay}
                onDateChange={handleDateChange}
                events={events}
                resources={resources}
                components={{
                    dayColumnWrapper: dayCol,
                    eventWrapper: eventsWrapper
                }}
            />
        </DashboardLayout>
    );
}