import styled from "styled-components";

interface InputProps {
    label?: string;
    type?: 'password' | 'text' | "email";
    placeholder?: string;
    $beforeIcon?: string;
    $afterIcon?: string;
    value?: string;
  }

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
  &.error {
    input {
      border: 1px solid #FC3F1D;
    }
  }
`;

export const Label = styled.label`
  font-size: 16px;
  line-height: 21px;
  color: ${props => props.theme.input.label};
  .required {
    color: #FC3F1D;
  }
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  position: relative;
`;

export const StyledInput = styled.input<InputProps>`
  padding: 10px;
  padding-left: ${props => props.$beforeIcon ? "44px" : "10px"};
  padding-right: ${props => props.$afterIcon ? "44px" : "10px"};
  /* color: ${props => props.theme.input.text}; */
  border-radius: 8px;
  background: #fff;
  width: 100%;
  border: 1px solid #E1E4EA;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color: #282A42;
  &::placeholder {
    color: #CDCDDF;
  };
  &:focus, &:active, &:focus-visible {
    outline: none;;
  }
  &::disabled::placeholder {
    color: #282A42;
  }
`;

export const StyleBeforeIcon = styled.div`
  position: absolute;
  left: 10px;
  height: 25px;
`;

export const StyleAfterIcon = styled.div`
  position: absolute;
  right: 10px;
  cursor: pointer;
  display: flex;
  div {
    height: 25px;
  }
`;