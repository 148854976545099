import { axiosApi } from "shared/api/axios";
import { ProfileTypes } from "../types/ProfileTypes";

export const getProfileClients = async (id: any): Promise<ProfileTypes> => {
    try {
        const response = await axiosApi.get<ProfileTypes>(`/api/v1/counterparties/counterparty/${id}`);
        return response.data;
    } catch(err) {
        console.error(err);
        throw new Error("Ошибка получения профиля");
    }
}