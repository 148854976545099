import { FormikInput } from "shared/components/FormikInput"
import { WrapperFormProfile } from "shared/styles/GlobalStyle"
import { ContactsData } from "../../consts/SubOrganization/ContactsData"

export const OrgContactsDataInput = () => {
    return (
        <WrapperFormProfile>
            <div className="title">Контактное лицо</div>
            <div className="container">
                    {ContactsData.map((data, index) => (
                        <div className="row" key={index}>
                            {data.map((item: any, index) => (
                                <FormikInput
                                    key={index}
                                    name={item.name}
                                    label={item.label}
                                    placeholder={item.placeholder}
                                    type={item.type}
                                    maskConfig={item.maskConfig}
                                    className="inputForm"
                                    $afterIcon="clearInput"
                                />
                            ))}
                        </div>
                    ))}
            </div>
        </WrapperFormProfile>
    )
}