import { DashboardLayout } from "app/layout"
import { Button } from "shared/components/Button"
import { StyledItem, StyledWrapperColumn, StyledWrapperTable, StyledWrapperWherePlace, TabsWrapper, WrapperAvatar } from "./style";
import { useState } from "react"
import { Tab, Tabs } from "shared/components/Tabs"
import { Avatar } from "shared/components/Avatar"
import { Input } from "shared/components/Input"
import { useNavigate, useParams } from "react-router-dom"
import { axiosApi } from "shared/api/axios";
import { useQuery } from "@tanstack/react-query"
import { Table } from "shared/components/Table"
import { dataColumnsProfile, file } from "./statics"
import { StyledLine, WrapperFormProfile } from "shared/styles/GlobalStyle";
import { BackButton } from "shared/components/BackButton";

const columns = [
    {
        header: "Фото",
        cell: (row: any) => {
            const value = row.renderValue();
            return (
                <div className="bodyCellImg">
                    {typeof value === 'object' ? <div className="bodyCellImgBg"></div> : <img src={value} alt={"Фото"} />}
                </div>
            )
        },
        accessorKey: "photo"
    },
    {
        header: "Имя",
        cell: (row: any) => {
            const value = row.renderValue();
            return value;
        },
        accessorKey: "name"
    },
    {
        header: "Телефон",
        cell: (row: any) => {
            return row.renderValue()
        },
        accessorKey: "phone"
    },
    {
        header: "Количество",
        cell: (row: any) => {
            return row.renderValue()
        },
        accessorKey: "count"
    },
    // {
    //     header: "Время и дата брони",
    //     cell: (row: any) => {
    //         return row.renderValue()
    //     },
    //     accessorKey: "date"
    // },
    // {
    //     header: "Сумма",
    //     cell: (row: any) => {
    //         return row.renderValue()
    //     },
    //     accessorKey: "summ"
    // },
]

export const EquipmentProfile = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isEditable, setIsEditable] = useState(false);
    const [editButtonText, setEditButtonText] = useState("Редактировать");
    
    const toggleEdit = () => {
        setIsEditable(!isEditable);
        setEditButtonText(isEditable ? "Редактировать" : "Сохранить");
    };

    const handleDeleteProfile = () => {
        axiosApi.delete(`/api/v1/counterparties/counterparty/${id}`);
        navigate('/clients')
    }

    const handleAddBlackList = () => {
        // axiosApi.post(`/api/v1/counterparties/counterparty/${id}`);
    }

    const getProfile = async () => {
        try {
            const response = await axiosApi.get<any>("/api/v1/counterparties/counterparty/");
            return response.data.counterparties;
        } catch(err) {

        }
    }

    const {isLoading, isError, data} = useQuery({
        queryKey: ["getProfile"],
        queryFn: getProfile
    });

    const handleGoBack = () => {
        navigate('/clients');
    }

    const columnWidths = ['40px', ...Array(columns.length).fill('1fr')];
    const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});

    const handleSelectionChange = (newSelectedRows: Record<string, boolean>) => {
        setSelectedRows(newSelectedRows);
    };

    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <StyledItem>
                    <BackButton 
                        text="Назад"
                        onClick={handleGoBack}
                    />
                    <div className="dashboard__header-title">Допы</div>
                </StyledItem>
                <div className="dashboard__right-menu">
                    <Button themeType="delete" onClick={() => handleDeleteProfile()}>Удалить</Button>
                </div>
            </div>
        }>
            <TabsWrapper>
                <Tabs>
                    <Tab label="Расходник">
                        <WrapperAvatar>
                            <Avatar photo="/pepsi.png" name="Pepsi" jobs="#3243252 Расходные материалы" />
                            <div className="editButton" onClick={toggleEdit}>{editButtonText}</div>
                        </WrapperAvatar>
                        <StyledLine margin="20px 0"/>
                        <WrapperFormProfile>
                            <div className="title">Общие данные</div>
                            <div className="container">
                                <div className="row">
                                    <Input
                                        name=""
                                        label="В наличии, шт" 
                                        value="1 000"
                                        placeholder="В наличии, шт"
                                        className="inputForm"
                                        disabled={!isEditable}
                                    />
                                    <Input 
                                        name=""
                                        label="Продано" 
                                        placeholder="Продано"
                                        value="20:00 -100 00:00"
                                        disabled={!isEditable}
                                        className="inputForm"
                                    />
                                    <Input 
                                        name=""
                                        label="Заработано всего, ₽" 
                                        placeholder="Заработано всего, ₽"
                                        value="10000"
                                        className="inputForm"
                                        disabled={!isEditable}
                                    />
                                    <Input 
                                        name=""
                                        label="Ср. кол-во продаж, день" 
                                        placeholder="Ср. кол-во продаж, день"
                                        value="100"
                                        className="inputForm"
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                        </WrapperFormProfile>
                        <StyledWrapperTable>
                            <div className="title">История заказов</div>
                            <Table 
                                className="orderProfile" 
                                data={dataColumnsProfile}
                                columns={columns} 
                                columnWidths={columnWidths}
                                // onSelectionChange={handleSelectionChange}
                            />
                        </StyledWrapperTable>
                    </Tab>
                    <Tab label="Оборудование">
                    <WrapperAvatar>
                            <Avatar photo="/lampa.png" name="Лампа" jobs="#3243252 Оборудование" />
                            <div className="editButton" onClick={toggleEdit}>{editButtonText}</div>
                        </WrapperAvatar>
                        <StyledLine margin="20px 0"/>
                        <WrapperFormProfile>
                            <div className="title">Общие данные</div>
                            <div className="container">
                                <div className="row">
                                    <Input
                                        name=""
                                        label="В наличии, шт" 
                                        value="1 000"
                                        placeholder="В наличии, шт"
                                        className="inputForm"
                                        disabled={!isEditable}
                                    />
                                    <Input 
                                        name=""
                                        label="Доступные к заказу" 
                                        placeholder="Доступные к заказу"
                                        value="1000"
                                        disabled={!isEditable}
                                        className="inputForm"
                                    />
                                    <Input 
                                        name=""
                                        label="Заработано всего, ₽" 
                                        placeholder="Заработано всего, ₽"
                                        value="180,000"
                                        className="inputForm"
                                        disabled={!isEditable}
                                    />
                                    <Input 
                                        name=""
                                        label="Ср. кол-во продаж, день" 
                                        placeholder="Ср. кол-во продаж, день"
                                        value="105"
                                        className="inputForm"
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div className="row">
                                    <Input
                                        name=""
                                        label="Цена, ₽/час" 
                                        value="₽ 100"
                                        placeholder="Цена, ₽/час"
                                        className="inputForm"
                                        disabled={!isEditable}
                                    />
                                    <Input 
                                        name=""
                                        label="Цена >3ч, ₽/час" 
                                        placeholder="Цена >3ч, ₽/час"
                                        value="₽ 100"
                                        disabled={!isEditable}
                                        className="inputForm"
                                    />
                                    <Input 
                                        name=""
                                        label="Утро, ₽/час" 
                                        placeholder="Утро, ₽/час"
                                        value="₽ 100"
                                        className="inputForm"
                                        disabled={!isEditable}
                                    />
                                    <Input 
                                        name=""
                                        label="Вечер, ₽/час" 
                                        placeholder="Вечер, ₽/час"
                                        value="₽ 100"
                                        className="inputForm"
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div className="row">
                                    <Input
                                        name=""
                                        label="Утренние часы" 
                                        value="08:00 - 10:00"
                                        placeholder="Утренние часы"
                                        className="inputForm"
                                        disabled={!isEditable}
                                    />
                                    <Input 
                                        name=""
                                        label="Вечерние часы" 
                                        placeholder="Вечерние часы"
                                        value="20:00 - 00:00"
                                        disabled={!isEditable}
                                        className="inputForm"
                                    />
                                </div>
                                <div className="row">
                                    <Input
                                        name=""
                                        label="Комментарий" 
                                        value="Комментарий"
                                        placeholder="Комментарий"
                                        className="inputForm"
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                        </WrapperFormProfile>
                        <StyledWrapperColumn>
                            <div className="rowBottomBlock">
                                <StyledWrapperTable>
                                    <div className="title">История заказов</div>
                                    <Table 
                                        className="orderProfile" 
                                        data={dataColumnsProfile}
                                        columns={columns} 
                                        columnWidths={columnWidths}
                                        // onSelectionChange={handleSelectionChange}
                                    />
                                </StyledWrapperTable>
                                <StyledWrapperWherePlace>
                                    <div className="title">Где сейчас находится</div>
                                    <div className="place">
                                        <div className="placeBox">
                                            <div className="placeBoxImg">
                                                <img src="/place.png" alt="" />
                                            </div>
                                            <div className="placeBoxContent">
                                                <div className="placeBoxContentTitle">Помещение</div>
                                                <div className="placeBoxContentDescription">
                                                    <p>ФИ / название компании</p>
                                                    <p>28.10.2023, 16:00 - 30.10.2023, 20:00</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="placeBox">
                                            <div className="placeBoxImg">
                                                <img src="/place.png" alt="" />
                                            </div>
                                            <div className="placeBoxContent">
                                                <div className="placeBoxContentTitle">Помещение</div>
                                                <div className="placeBoxContentDescription">
                                                    <p>ФИ / название компании</p>
                                                    <p>28.10.2023, 16:00 - 30.10.2023, 20:00</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="placeBox">
                                            <div className="placeBoxImg">
                                                <img src="/place.png" alt="" />
                                            </div>
                                            <div className="placeBoxContent">
                                                <div className="placeBoxContentTitle">Помещение</div>
                                                <div className="placeBoxContentDescription">
                                                    <p>ФИ / название компании</p>
                                                    <p>28.10.2023, 16:00 - 30.10.2023, 20:00</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </StyledWrapperWherePlace>
                            </div>
                        </StyledWrapperColumn>
                    </Tab>
                </Tabs>
            </TabsWrapper>
        </DashboardLayout>
    )
}