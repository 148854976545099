import { configureStore } from '@reduxjs/toolkit';
import sidebarReducer from "./slice/sidebar";
import modalReducer from "./slice/modalSlice";
import notificationReducer from "./slice/notificationSlice";
import authReducer from 'features/auth/model/authSlice';
import { useSelector } from 'react-redux';

export const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    auth: authReducer,
    modal: modalReducer,
    notification: notificationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const isModal = (state: RootState) => {
  return {
    isOpen: state.modal.isOpen,
    isContent: state.modal.content,
    isConfig: state.modal.config
  }
};

export const isNotification = (state: RootState) => {
  return {
    isMessage: state.notification.message,
    isType: state.notification.type
  }
};