import styled from "styled-components";

export const WrapperCalendar  = styled.div`
    height: 100%;
    .rbc-allday-cell, .rbc-month-header {
        display: none;
    }
    .rbc-day-slot .rbc-events-container {
        margin-right: 0;
    }
    /* Месяц календарь */
    .rbc-month-view {
        border-radius: 8px;
        border: 1px solid #E1E4EA;
        overflow: hidden;
        .rbc-day-bg {
            border-left: 1px solid #E1E4EA;
        }
        .rbc-off-range-bg {
            background: #F6F9FF;
        }
        .rbc-date-cell {
            text-align: left;
            padding-right: 0;
            padding-left: 12px;
            padding-top: 12px;
            display: flex;
            .rbc-button-link {
                color: #282A42;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
        .rbc-off-range {
            .rbc-button-link {
                color: #8181A5;
            }
        }
        .rbc-today {
            background: #F6F9FF;
        }
    }
    /* Конец месяца календаря */

    .rbc-time-view {
        border: none;
        border-right: 1px solid #E1E4EA;
        border-radius: 8px 8px 0 0;
        overflow: hidden;
    }
    .rbc-time-view-resources .rbc-label.rbc-time-header-gutter {
        background-color: #E1E4EA;
    }
    .rbc-time-header-content > .rbc-row.rbc-row-resource {
        border-bottom: none;
    }
    .rbc-time-header-content {
        border-left: 1px solid #E1E4EA;
    }
    .rbc-current-time-indicator {
        display: none;
    }
    .rbc-time-header {
        border: none;
        background-color: #E1E4EA;
        border-radius: 8px 8px 0 0;
        .rbc-header {
            border: none;
            color: #282A42;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            padding: 6px 0;
        }
    }
    .rbc-today {
        background: inherit;
    }
    .rbc-time-content {
        border: none;
        .rbc-time-gutter {
            .rbc-timeslot-group {
                padding: 17px 10px!important;
                border-bottom: 1px solid #E1E4EA!important;
                border-left: none!important;
                span {
                    color: #1C1D21;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                }
                &:nth-child(2n) {
                    span {
                        opacity: 0;
                    }
                }
            }
        }
        .rbc-time-column {
            .rbc-timeslot-group {
                min-height: 55.5px;
                border-left: 1px solid #E1E4EA;
                border-bottom: 1px solid #E1E4EA;
            }
        }
    }

    /* Кастомные блоки брони */
    .eventsCustom {
        &__day {
            padding: 3px 13px;
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 3px;
            border-right: 1px solid #E1E4EA;
            background: #E3F8EF;
            &::before {
                content: "";
                width: 3px;
                height: 100%;
                position: absolute;
                left: 5px;
                top: 0;
                background-color: #34BD93;
                border-radius: 6px;
            }
            &-info {
                color: #34BD93;
                font-family: Lato;
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }
`;