export const listPages = [
    {
        name: "Залы",
        link: "/halls/",
    },
    {
        name: "Дополнительное оборудование",
        link: "/equipment/",
    },
    {
        name: "Сотрудники",
        link: "/employees/",
    }
]