import { axiosApi } from "shared/api/axios";

interface IUploadFileResponse {
    filename: string;
    hash_name: string;
    upload_url: string;
}

interface IPropsUploadFile {
    filenames: string[];
}

export const getUploadUrls = async ({
    filenames
}: IPropsUploadFile): Promise<IUploadFileResponse[]> => {
    const response = await axiosApi.post("api/v1/attachments/attachment/upload_urls", { filenames });
    return response.data;
}