import React from "react";
import { AccordionWrapper } from "./style";
import { Input } from "shared/components/Input";
import { Button } from "shared/components/Button";

interface IPropsAccordionCustom {
    title?: string;
    children: React.ReactNode;
    isOpen?: boolean,
    // handleClose?: (value: boolean) => void
}

export const AccordionCustom = ({
    title,
    children,
    isOpen,
    // handleClose
}: IPropsAccordionCustom) => {
    return (
        <AccordionWrapper>
            <div className="header">
                <div className="title">{title}</div>
                <div className="toggle-arrow">
                    <div className="toggle-arrow-item" style={{transform: isOpen ? "rotate(180deg)" : "rotate(0deg)"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18" fill="none">
                            <path d="M17 6.49951L12 11.4995L7 6.49951" stroke="#363853" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
            {isOpen && (
                <div className="content">
                    <div className="row">
                        <Input name="" label="Номер заказа" value="12351646516" disabled />
                        <Input name="" value="Создание" disabled />
                    </div>
                    <Input name="" label="Контактный телефон" value="+7 777 777 77 77" required />
                    <Input name="" label="ФИО" value="Иванов Иван Иванович" required />
                    <Input name="" label="Контактный Email" placeholder="name@sitename.ru" />
                    <Button>Далее</Button>
                </div>
            )}
        </AccordionWrapper>
    )
}