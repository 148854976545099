import { StyledWrapperAvatar } from "./style"

interface IPropsAvatar {
    photo: string | undefined;
    name: string | undefined;
    jobs: string;
}

export const Avatar = ({
    photo,
    name,
    jobs
}: IPropsAvatar) => {
    return (
        <StyledWrapperAvatar>
            <div className="avatarPhoto">
                <img src={photo} alt="" />
            </div>
            <div className="avatarContent">
                <div className="avatarContent__name">{name}</div>
                <div className="avatarContent__jobs">{jobs}</div>
            </div>
        </StyledWrapperAvatar>
    )
}