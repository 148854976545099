import { FormikInput } from "shared/components/FormikInput"
import { WrapperFormProfile } from "shared/styles/GlobalStyle"
import { DocumentsData } from "../../consts/SubPersonal/DocumentsData"
import { useEffect, useState } from "react"
import { UploadFile } from "entities/uploadFiles";

interface IPropsDocumentsDataInput {
    onChangeFile: (file: any) => void;
}

interface IPropsFile {
    id: number;
    name: string;
}

export const DocumentsDataInput = ({
    onChangeFile
}: IPropsDocumentsDataInput) => {
    const [files, setFiles] = useState<IPropsFile[]>([]);

    const handleFileChange = (newFiles: IPropsFile[]) => {
        setFiles(newFiles);
        onChangeFile && onChangeFile(newFiles);
    };

    useEffect(() => {
        onChangeFile && onChangeFile(files);
    }, [files, onChangeFile]);

    return (
        <WrapperFormProfile>
            <div className="title">Документы</div>
            <div className="container">
                    {DocumentsData.map((data, index) => (
                        <div className="row" key={index}>
                            {data.map((item, index) => (
                                <FormikInput
                                    key={index}
                                    name={item.name}
                                    label={item.label}
                                    placeholder={item.placeholder}
                                    type={item.type}
                                    className="inputForm"
                                    $afterIcon="clearInput"
                                    maskConfig={item.maskConfig}
                                />
                            ))}
                        </div>
                    ))}
                    <div className="row">
                        <UploadFile 
                            file={[]} 
                            disabled={true} 
                            onChangeFile={handleFileChange}
                        />
                    </div>
            </div>
        </WrapperFormProfile>
    )
}