import { useRef, useEffect } from "react";
import ReactCodeInput from "react-code-input";
import { AuthWrapper, BackLink, Box, Description, RowCode, Title, Wrapper } from "../style"
import { Button } from "shared/components/Button";

interface IPropsConfirmCodeForm {
    setIsConfirm: (value: boolean) => void;
  }

export const ConfirmCodeForm = ({ setIsConfirm }: IPropsConfirmCodeForm) => {
    const inputRef = useRef<any>(null)

    useEffect(() => {
        if (null !== inputRef.current){
        document.querySelectorAll("input").forEach((node: HTMLInputElement) => node.setAttribute('placeholder', '0'));
        }
    })
    return (
        <AuthWrapper>
            <Box>
                <Wrapper>
                    <Title>Подтвердите вход</Title>
                    <Description>Введите код, который пришел на почту name@sitename.com или номер телефона <div>+ 7 999 999 99 99</div></Description>
                    <RowCode>
                        <ReactCodeInput ref={inputRef} placeholder="0" type='number' fields={6} name="code" inputMode="numeric" />
                    </RowCode>
                    <Button themeType="primary">Подтвердить</Button>
                    <BackLink onClick={() => setIsConfirm(false)}>Назад</BackLink>
                </Wrapper>
            </Box>
        </AuthWrapper>
    )
}