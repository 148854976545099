import styled from "styled-components";

interface IPropsCheckbox {
    checked: boolean;
}

export const CheckboxContainer = styled.label`

`;

export const Icon = styled.svg`
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    opacity: 0;
    position: absolute;
    left: -9999px;
`;

export const StyledCheckbox = styled.div<IPropsCheckbox>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: ${props => (props.checked ? '#5E81F4' : 'rgba(129, 129, 165, 0.30)')};
    border-radius: 4px;
    transition: all 150ms;
    ${HiddenCheckbox}:focus + & {
        box-shadow: 0 0 0 3px pink;
    }
    ${Icon} {
        visibility: ${props => (props.checked ? 'visible' : 'hidden')}
    }
`;