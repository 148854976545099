import { DashboardLayout } from "app/layout"
import { StyledItem } from "../clientsProfile/style"
import { Input } from "shared/components/Input"
import { WrapperFormProfile } from "shared/styles/GlobalStyle"
import { BackButton } from "shared/components/BackButton"
import { useNavigate } from "react-router-dom"
import { UploadFile } from "entities/uploadFiles"


const documents = [
    {
        id: 1,
        name: "Договор №134526 от 20.28.2023.pdf",
    },
    {
        id: 2,
        name: "Договор №134526 от 20.28.2023.pdf",
    },
    {
        id: 3,
        name: "Договор №134526 от 20.28.2023.pdf",
    },
    {
        id: 4,
        name: "Договор №134526 от 20.28.2023.pdf",
    },
];

const check = [
    {
        id: 1,
        name: "Чек 1244",
    },
    {
        id: 2,
        name: "Чек 1244",
    },
];

export const Booking = () => {

    const navigate = useNavigate();

    const onBackPage = () => {
        navigate(-1);
    };
    
    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <StyledItem>
                    <BackButton
                        text="Назад"
                        onClick={onBackPage}
                    />
                    <div className="dashboard__header-title">Бронирование</div>
                </StyledItem>
            </div>
        }>
            <WrapperFormProfile>
                <div className="title">1.Общие данные</div>
                <div className="container">
                    <div className="row">
                        <Input
                            name=""
                            label="Номер заказа" 
                            placeholder="Номер заказа" 
                            value="№343251235"
                            className="inputForm"
                            disabled
                        />
                        <Input
                            name=""
                            label="Дата и время" 
                            value="23.08.2023, 15:00 - 19:00"
                            placeholder="Дата и время"
                            className="inputForm"
                            disabled
                        />
                        <Input
                            name=""
                            label="Заказчик" 
                            value="Иванов Иван Иванович"
                            placeholder="Заказчик"
                            className="inputForm"
                            disabled
                        />
                    </div>
                    <div className="row">
                        <Input
                            name=""
                            label="Телефон" 
                            value="+7-999-999-9999"
                            placeholder="Телефон"
                            className="inputForm"
                            disabled
                        />
                        <Input 
                            name=""
                            label="Почта" 
                            placeholder="Почта"
                            value="Ivanov.Ivan@gmail.com"
                            disabled
                            className="inputForm"
                        />
                        <Input 
                            name=""
                            label="Документы" 
                            placeholder="Документы"
                            value="Название документа"
                            disabled
                            $afterIcon="download"
                            className="inputForm"
                        />
                    </div>
                </div>
            </WrapperFormProfile>
            <WrapperFormProfile>
                <div className="title">2.Данные по заказу</div>
                <div className="container">
                    <div className="row">
                        <Input
                            name=""
                            label="Помещение" 
                            placeholder="Помещение" 
                            value="Название 1"
                            className="inputForm"
                            disabled
                        />
                        <Input
                            name=""
                            label="Количество человек" 
                            value="8"
                            placeholder="Количество человек"
                            className="inputForm"
                            disabled
                        />
                        <Input
                            name=""
                            label="Сумма заказа" 
                            value="₽15,000"
                            placeholder="Сумма заказа"
                            className="inputForm"
                            disabled
                        />
                    </div>
                    <div className="row">
                        <Input
                            name=""
                            label="Дополнительное оборудование" 
                            value="Пепси, чай, кола, лампа"
                            placeholder="Дополнительное оборудование"
                            className="inputForm"
                            disabled
                        />
                        <Input 
                            name=""
                            label="Комментарий" 
                            placeholder="Комментарий"
                            value="Возможно опоздание"
                            disabled
                            className="inputForm"
                        />
                    </div>
                </div>
            </WrapperFormProfile>
            <WrapperFormProfile>
                <div className="container container-flex">
                    <div className="column">
                        <div className="title">4.Документы</div>
                        <UploadFile themeType="onlytitle" disabled={false} file={documents} />
                    </div>
                    <div className="column">
                        <div className="title">5.Чеки</div>
                        <UploadFile themeType="onlytitle" disabled={false} file={check} />
                    </div>
                </div>
            </WrapperFormProfile>
        </DashboardLayout>
    )
}