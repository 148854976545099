import moment from "moment";

export const columns = [
    {
        header: "",
        id: "photo",
        cell: (row: any) => {
            const value = row.renderValue();
            return (
                <div className="bodyCellImg">
                    {value ? <img src={value} alt={"Фото"} /> : <div className="bodyCellImgBg"></div>}
                </div>
            )
        },
        accessorKey: "profile_photo.read_url"
    },
    {
        header: "Имя",
        cell: (row: any) => {
            const contacts = row.renderValue();
            return contacts && contacts[0] ? <>{contacts[0].first_name} {contacts[0].patronymic} {contacts[0].surname}</> : "";
        },
        accessorFn: (row: any) => row?.contacts || [],
        id: "contact__surname"
    },
    {
        header: "Телефон",
        cell: (row: any) => {
            return row.renderValue();
        },
        accessorKey: "phone",
    },
    {
        header: "Почта",
        cell: (row: any) => {
            return row.renderValue();
        },
        accessorKey: "email",
    },
    {
        header: "Количество часов брони",
        cell: (row: any) => {
            return row.renderValue() ? row.renderValue() : 0;
        },
        accessorKey: "booking_hours"
    },
    {
        header: "Дата регистрации",
        cell: (row: any) => {
            return moment(row.renderValue()).format("DD.MM.YYYY")
        },
        accessorKey: "created_at"
    },
]