import IMask from "imask";
import moment from "moment";

export const DocumentsData = [
    [
        { 
            name: "additional_data.documents.serial", 
            label: "Серия и номер", 
            placeholder: "3784 148095", 
            type: "text",
            maskConfig: { mask: '0000 000000' }, 
        },
        { 
            name: "additional_data.documents.issue_date", 
            label: "Дата выдачи", 
            placeholder: "22.09.1993", 
            type: "text",
            maskConfig: {
                mask: Date,
                pattern: 'd{.}`m{.}`Y',
                blocks: {
                    d: {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 31,
                        maxLength: 2,
                    },
                    m: {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 12,
                        maxLength: 2,
                    },
                    Y: {
                        mask: IMask.MaskedRange,
                        from: 1945,
                        to: moment().format("YYYY"),
                        maxLength: 4,
                    },
                }
            },
        },
        { 
            name: "additional_data.documents.division_code", 
            label: "Код подразделения", 
            placeholder: "770-251", 
            type: "text",
            maskConfig: { mask: '000-000' }, 
        },
        { 
            name: "additional_data.documents.issued_by", 
            label: "Кем выдан", 
            placeholder: "ОУФМС МВД РОССИИ ПО МОССКОВСКОЙ ОБЛАСТИ", 
            type: "text",
            maskConfig: { mask: /^[A-Za-zА-Яа-яЁё\s]*$/ }, 
        },
    ],
];