import { CheckboxContainer, HiddenCheckbox, Icon, StyledCheckbox } from "./style";

interface IPropsCheckbox {
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = ({ className, checked, ...props }: IPropsCheckbox & React.InputHTMLAttributes<HTMLInputElement>) => (
    <CheckboxContainer className={className}>
        <HiddenCheckbox checked={checked} {...props} />
        <StyledCheckbox checked={checked}>
            <Icon xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9" fill="none">
                <path d="M3.6437 7.90538L0.157101 4.41878C-0.0523671 4.20931 -0.0523671 3.86968 0.157101 3.66019L0.915668 2.9016C1.12514 2.69211 1.46479 2.69211 1.67426 2.9016L4.023 5.25032L9.05374 0.219601C9.26321 0.0101329 9.60286 0.0101329 9.81233 0.219601L10.5709 0.978189C10.7804 1.18766 10.7804 1.52729 10.5709 1.73678L4.40229 7.9054C4.1928 8.11487 3.85317 8.11487 3.6437 7.90538Z" fill="white"/>
            </Icon>
        </StyledCheckbox>
    </CheckboxContainer>
);