export const dataColumnsProfile = [
    {
        id: 1,
        photo: "/table/photo.png",
        name: "Иванов Иван Иванович",
        phone: "+7-999-999-9999",
        count: "300",
        date: "28.08.2023, 15:30 - 21:00",
        summ: "₽30000"
    },
    {
        id: 2,
        photo: "/table/photo.png",
        name: "Иванов Иван Иванович",
        phone: "+7-999-999-9999",
        count: "300",
        date: "28.08.2023, 15:30 - 21:00",
        summ: "₽30000"
    },
    {
        id: 3,
        photo: "/table/photo.png",
        name: "Иванов Иван Иванович",
        phone: "+7-999-999-9999",
        count: "300",
        date: "28.08.2023, 15:30 - 21:00",
        summ: "₽30000"
    },
    {
        id: 4,
        photo: "/table/photo.png",
        name: "Иванов Иван Иванович",
        phone: "+7-999-999-9999",
        count: "300",
        date: "28.08.2023, 15:30 - 21:00",
        summ: "₽30000"
    },
    {
        id: 5,
        photo: "/table/photo.png",
        name: "Иванов Иван Иванович",
        phone: "+7-999-999-9999",
        count: "300",
        date: "28.08.2023, 15:30 - 21:00",
        summ: "₽30000"
    },
    {
        id: 6,
        photo: "/table/photo.png",
        name: "Иванов Иван Иванович",
        phone: "+7-999-999-9999",
        count: "300",
        date: "28.08.2023, 15:30 - 21:00",
        summ: "₽30000"
    }
]

export const file = [
    {
        id: 1,
        name: "Паспорт",
    },
    {
        id: 2,
        name: "Права",
    },
    {
        id: 3,
        name: "Документы",
    },
]