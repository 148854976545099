import { DashboardLayout } from "app/layout";
import { Button } from "shared/components/Button";
import { TabLink } from "shared/components/TabLink";
import { linksCalendar } from "shared/consts/linksCalendar";
import { PeriodSelector } from "shared/components/PeriodSelector";
import moment, { Moment } from "moment";
import { useState } from "react";
import { ReactBigCalendar } from "features/ReactBigCalendar";

export const CalendarWeekPage = () => {
    const [currentWeek, setCurrentWeeek] = useState<Moment>(moment());

    const handleDateChange = (date: Moment) => {
        setCurrentWeeek(date);
    };

    const events = [
        {
            title: "Название",
            start: moment().add(1, 'day').toDate(),
            end: moment().add(1, 'day').toDate(),
            resource: "Ресурс"
        }
    ]

    const DayColumnWrapper = (e: any) => {
        return (
            <div>
                {moment(e.date).format("dd")}
            </div>
        );
    };

    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <TabLink list={linksCalendar} />
                <PeriodSelector period="week" onDateChange={handleDateChange} />
                <div className="dashboard__right-menu">
                    <Button>+ Новая бронь</Button>
                </div>
            </div>
        }>
            <ReactBigCalendar
                views={{
                    week: true
                }}
                defaultView="week"
                min={new Date(new Date().setHours(9, 0, 0, 0))}
                max={new Date(new Date().setHours(17, 0, 0, 0))}
                currentDay={currentWeek}
                onDateChange={handleDateChange}
                events={events}
                components={{
                    header: DayColumnWrapper
                }}
            />
        </DashboardLayout>
    );
}