import { DashboardLayout } from "app/layout"
import { NavLink } from "react-router-dom"
import { Button } from "shared/components/Button"
import { TabLink } from "shared/components/TabLink"
import { listPages } from "shared/consts/linksControlPages"
import { HallsListWrapper } from "./style"

const halls = [
    {
        name: "Все залы",
        link: "/halls/"
    },
    {
        name: "Название 1",
        link: "/halls/profile/1"
    },
    {
        name: "Название 2",
        link: "/halls/profile/2"
    },
    {
        name: "Название 3",
        link: "/halls/profile/3"
    },
]

export const HallsList = () => {
    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <div className="dashboard__header-title">Управление</div>
                <TabLink list={listPages} />
                <div className="dashboard__right-menu" style={{marginLeft: "inherit"}}>
                    <Button>+Добавить зал</Button>
                </div>
            </div>
        }>
            <HallsListWrapper>
                <div className="halls__list">
                    {halls.map((item, index) => (
                        <NavLink key={index} to={item.link}>
                            {item.name}
                        </NavLink>
                    ))}
                </div>
                {/* <div className="halls__info">
                    <div className="halls__info-left">
                        <div className="halls__card"></div>
                        <div className="halls__card"></div>
                    </div>
                    <div className="halls__info-right">
                        <div className="halls__card"></div>
                    </div>
                </div> */}
            </HallsListWrapper>
        </DashboardLayout>
    )
}