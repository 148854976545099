import { DashboardLayout } from "app/layout";
import { Button } from "shared/components/Button";
import { TabLink } from "shared/components/TabLink";
import { linksCalendar } from "shared/consts/linksCalendar";
import { PeriodSelector } from "shared/components/PeriodSelector";
import { useState } from "react";
import { Moment } from "moment";
import moment from "moment";
import { ReactBigCalendar } from "features/ReactBigCalendar";

export const CalendarMonthPage = () => {
    const [currentMonth, setCurrentMonth] = useState<Moment>(moment());

    const handleDateChange = (date: Moment) => {
        setCurrentMonth(date);
    };

    const views = {
        month: true
    };

    const events = [
        {
            title: "Название",
            start: moment().add(1, 'day').toDate(),
            end: moment().add(1, 'day').toDate(),
            resource: "Ресурс"
        }
    ]

    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <TabLink list={linksCalendar} />
                <PeriodSelector period="month" onDateChange={handleDateChange} />
                <div className="dashboard__right-menu">
                    <Button>+ Новая бронь</Button>
                </div>
            </div>
        }>
            <ReactBigCalendar
                views={views}
                defaultView="month"
                min={new Date(new Date().setHours(9, 0, 0, 0))}
                max={new Date(new Date().setHours(17, 0, 0, 0))}
                currentDay={currentMonth}
                onDateChange={handleDateChange}
                events={events}
            />
        </DashboardLayout>
    );
}