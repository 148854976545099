import moment from "moment";

export const GeneralData = [
    [
        { 
            name: "additional_data.documents.organization_name", 
            label: "Наименование", 
            placeholder: "Введите", 
            type: "text",
            required: true,
        },
        { 
            name: "additional_data.documents.inn", 
            label: "ИНН", 
            placeholder: "Введите", 
            type: "text",
            maskConfig: {
                mask: Number
            }
        },
        { 
            name: "additional_data.documents.kpp", 
            label: "КПП", 
            placeholder: "Введите", 
            type: "text",
            maskConfig: {
                mask: Number
            }
        },
        { 
            name: "additional_data.documents.registration_date", 
            label: "Дата регистрации",
            value: moment().format("DD.MM.YYYY"),
            disabled: true,
            placeholder: "18.08.2023", 
            type: "text" 
        },
    ],
    [
        { 
            name: "additional_data.documents.bank_name", 
            label: "Название банка", 
            placeholder: "Введите", 
            type: "text",
        },
        { 
            name: "additional_data.documents.bik", 
            label: "БИК банка", 
            placeholder: "Введите", 
            type: "text",
            maskConfig: {
                mask: Number
            }
        },
        { 
            name: "additional_data.documents.checking_account", 
            label: "Расчетный счет", 
            placeholder: "Введите", 
            type: "text",
            maskConfig: {
                mask: Number
            }
        },
        { 
            name: "additional_data.documents.correspondent_account", 
            label: "Кореспонденческий счет", 
            placeholder: "Введите", 
            type: "text",
            maskConfig: {
                mask: Number
            }
        },
    ],
    [
        { 
            name: "phone", 
            label: "Телефон", 
            placeholder: "Введите", 
            type: "text",
            required: true,
            maskConfig: { mask: '+{7}-000-000-00-00' }, 
        },
        { 
            name: "email", 
            label: "Почта", 
            placeholder: "Введите", 
            type: "text",
            required: true,
        },
        { 
            name: "additional_data.documents.legal_address", 
            label: "Юридический адрес", 
            placeholder: "Введите", 
            type: "text",
        },
        { 
            name: "discount", 
            label: "Постоянная скидка", 
            placeholder: "Введите", 
            type: "number",
            maskConfig: {
                mask: Number,
                min: 0,
                max: 100
            }
        },
    ]
];