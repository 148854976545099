import styled from 'styled-components';

export const WrapperNotifications = styled.div`
    position: fixed;
    right: 30px;
    top: 30px;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.80);
    background: rgba(227, 248, 239, 0.50);
    backdrop-filter: blur(15px);
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const NotificationsMessage = styled.div`
    color: #282A42;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

export const NotificationsClose = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    background: #8181A5;
    width: 12px;
    height: 12px;
    border-radius: 50px;
    margin-left: 20px;
    cursor: pointer;
`;

export const NotificationsIcon = styled.div`
    margin-right: 10px;
    position: relative;
    top: 2px;
`;