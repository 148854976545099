import { PropsWithChildren, useEffect } from "react"
import { createPortal } from "react-dom";
import { useScrollbarWidth } from "shared/hooks/useScrollbarWidth";
import { ModalClose, ModalContentTop, WrapperModal, WrapperModalContent } from "./style";
import { Icon } from "../Icons";
import { Button } from "../Button";
import { useDispatch } from "react-redux";
import { closeModal } from "shared/redux/slice/modalSlice";

interface IPropsBtnsModal {
    onClick: () => void;
    title: string;
    active: boolean;
}

interface IPropsModal {
    isOpen: boolean;
    className?: string;
    contentClassName?: string;
    childrenClassName?: string;
    title: string;
    btns?: IPropsBtnsModal[];
}

interface IPropsModalConent {
    className?: string;
    childrenClassName?: string;
    title: string;
};

export const ModalContent: React.FC<PropsWithChildren<IPropsModalConent>> = ({
    children,
    className,
    childrenClassName,
    title,
}) => {
    const scrollbarWidth = useScrollbarWidth();
    const dispatch = useDispatch();
    useEffect(() => {
        const listenerHandler = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                dispatch(closeModal())
            }
        };

        document.addEventListener("keydown", listenerHandler);
        document.body.style.overflow = "hidden";
        document.body.style.paddingRight = `${scrollbarWidth}px`;

        return () => {
        document.removeEventListener("keydown", listenerHandler);
        document.body.style.removeProperty("overflow");
        document.body.style.paddingRight = "0px";
        };
    }, [scrollbarWidth]);
    return (
        <WrapperModalContent className={className}>
            <ModalContentTop>
                <div className="modal-title">{title}</div>
                <ModalClose
                    onClick={() => dispatch(closeModal())}
                >
                    <Icon name="close" />
                </ModalClose>
            </ModalContentTop>
            <div className={childrenClassName}>{children}</div>
        </WrapperModalContent>
    )
}

export const Modal: React.FC<PropsWithChildren<IPropsModal>>= ({
    isOpen = false,
    className,
    contentClassName,
    childrenClassName,
    children,
    title,
    btns
}) => {
    const dispatch = useDispatch();
    if (!isOpen) return null;
    return createPortal(
        <WrapperModal className={className}>
            <ModalContent
                title={title}
                className={contentClassName}
                childrenClassName={childrenClassName}
            >
                <>
                    {children}
                    <div className="modalcontent__btns">
                        {btns && btns.map((item, index) => (
                            <Button key={index} onClick={item.onClick} className={item.active ? '' : 'modalcontent__btns--outline'}>{item.title}</Button>
                        ))}
                    </div>
                </>
            </ModalContent>
        </WrapperModal>,
        document.body as HTMLElement
    );
}