import React from 'react';


const LogoIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="64" viewBox="0 0 80 64" fill="none">
        <path d="M26 29.8575C26 22.2042 32.2042 16 39.8575 16V35.1425C39.8575 42.7958 33.6533 49 26 49V29.8575Z" fill="#4A3AFF"/>
        <path d="M41.8677 23.5301C41.8677 19.3713 45.239 16 49.3977 16C53.5565 16 56.9278 19.3713 56.9278 23.5301V23.9648C56.9278 28.1236 53.5565 31.4949 49.3977 31.4949C45.239 31.4949 41.8677 28.1236 41.8677 23.9648V23.5301Z" fill="#4CBFFF"/>
        <path d="M41.8677 41.0352C41.8677 36.8764 45.239 33.5051 49.3977 33.5051C53.5565 33.5051 56.9278 36.8765 56.9278 41.0352V41.47C56.9278 45.6287 53.5565 49 49.3977 49C45.239 49 41.8677 45.6287 41.8677 41.47V41.0352Z" fill="#9328FF"/>
    </svg>
);

export default LogoIcon;