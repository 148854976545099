import { useEffect } from "react";
import { RootState } from "shared/redux/store";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "shared/redux/hooks";
import { SignIn } from "features/auth/ui/SignIn";

export const SignInPages = () => {
  const navigate = useNavigate();
  const { isAuth } = useAppSelector((state: RootState) => state.auth);

  useEffect(() => {
    if(isAuth) {
      navigate('/');
    } else {
      navigate('/auth/sign-in')
    }
  }, [isAuth, navigate])

  return (
    <SignIn />
  );
}