import * as Yup from 'yup';

export const ValidateSchemaOrg = Yup.object().shape({
    phone: Yup.string().required().matches(/^\+7-\d{3}-\d{3}-\d{2}-\d{2}$/),
    email: Yup.string().email().required('Required'),
    additional_data: Yup.object().shape({
        documents: Yup.object().shape({
            organization_name: Yup.string().required()
        }),
    }),
});