import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const WrapperTabLink = styled.div`
    border-radius: 8px;
    overflow: hidden;
    background: #F4F3FA;
    display: flex;
    gap: 6px;
`;

export const TabLinkStyled = styled(NavLink)`
    color: #8181A5;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 11px 23.5px;
    text-decoration: none;
    &.active {
        border-radius: 8px;
        border: 1px solid #5E81F4;
        background: #fff;
        color: #282A42;
    }
`;