import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { Table } from "shared/components/Table";
import { getEmployees } from "../api/getEmployees";
import { SortingState } from "@tanstack/react-table";
import { columns } from "../model/columns";
import { useDebounce } from "shared/hooks/useDebounce";

interface IPropsEmployeesTable {
    onSelectedRowsChange: (selectedRows: number[]) => void;
}

export const EmployeesTable = ({
    onSelectedRowsChange
}:IPropsEmployeesTable) => {

    const [currentPage, setCurrentPage] = useState(0);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [search, setSearch] = useState<string>("");
    
    const debouncedSearchTerm = useDebounce(search, 1000);

    const handleSelectionChange = (newSelectedRows: any[]) => {
        onSelectedRowsChange(newSelectedRows);
        setSelectedRows(newSelectedRows);
    };

    const { isLoading, isError, data, refetch} = useQuery({
        queryKey: ["getEmployees", currentPage, search, sorting],
        queryFn: ({signal}) => {
            const order_by = sorting.length ? sorting[0].id : "created_at";
            const order_asc = sorting.length ? sorting[0].desc === true : false;
            return getEmployees(currentPage, search, order_by, order_asc, signal);
        },
        enabled: debouncedSearchTerm !== null,
        placeholderData: keepPreviousData,
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if (debouncedSearchTerm === "") {
            refetch();
        }
    }, [debouncedSearchTerm, refetch]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const onSearch = (e: any) => {
        setSearch(e.target.value);
    }

    return (
        <>
            {!isLoading && data && (
                <Table 
                    className="clientsTable" 
                    data={data.users}
                    columns={columns} 
                    columnWidths={['40px', '69px']}
                    selectedRows={selectedRows}
                    onSelectionChange={handleSelectionChange}
                    navigation="/employees/profile"
                    totalPages={data.pagination.total_pages}
                    onPageChange={handlePageChange}
                    isLoading={isLoading}
                    isError={isError}
                    onSortChange={setSorting}
                />
            )}
        </>
    )
}