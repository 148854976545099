import React from 'react';
import styled, { CSSProperties } from 'styled-components';
import UserIcon from './IconsList/userIcon';
import LockIcon from './IconsList/lockIcon';
import EmailIcon from './IconsList/emailIcon';
import HiddenIcon from './IconsList/hiddenIcon';
import ShowIcon from './IconsList/showicon';
import CheckIcon from './IconsList/checkicon';
import LogoIcon from './IconsList/logoicon';
import HomeIcon from './IconsList/navigationIcons/home';
import CalendarIcon from './IconsList/navigationIcons/calendar';
import MenuIcon from './IconsList/navigationIcons/menu';
import FolderIcon from './IconsList/navigationIcons/folder';
import PresentationIcon from './IconsList/navigationIcons/presentation';
import SettingIcon from './IconsList/navigationIcons/setting';
import ExitIcon from './IconsList/navigationIcons/exit';
import ArrowRight from './IconsList/rightSidebar/arrowRight';
import NotificationIcon from './IconsList/rightSidebar/notification';
import AddIcon from './IconsList/card/add';
import RemoveIcon from './IconsList/card/remove';
import RotateIcon from './IconsList/card/rotate';
import ChartIcon from './IconsList/card/chart';
import CheckBooking from './IconsList/rightSidebar/check';
import NotificationsUser from './IconsList/rightSidebar/notificationsUser';
import CloseIcon from './IconsList/modal/close';
import SearchIcon from './IconsList/searchicon';
import DeleteIcon from './IconsList/profile/deleteIcon';
import DownloadIcon from './IconsList/profile/downloadIcon';
import ClearInputIcon from './IconsList/profile/clearInputIcon';
import UserLoginIcon from './IconsList/auth/userloginicon';
import FourBlockIcon from './IconsList/navigationIcons/fourblock';
import SortingASCIcon from './IconsList/sortingASCIcon';
import SortingDescIcon from './IconsList/sortingDescIcon';
import PluseIcon from './IconsList/profile/pluseIcon';

interface PropsIcon {
    name: string;
    className?: string;
    style?: CSSProperties;
}

type Icons = {
    [key: string]: JSX.Element;
};

const StyledIcon = styled.div`
    display: contents;
`;

const icons: Icons = {
    user: <UserIcon />,
    lock: <LockIcon />,
    email: <EmailIcon />,
    hidden: <HiddenIcon />,
    show: <ShowIcon />,
    check: <CheckIcon />,
    logo: <LogoIcon />,
    // auth
    userLogin: <UserLoginIcon />,
    // navigation
    home: <HomeIcon />,
    calendar: <CalendarIcon />,
    users: <UserIcon />,
    menu: <MenuIcon />,
    folder: <FolderIcon />,
    presentation: <PresentationIcon />,
    setting: <SettingIcon />,
    fourblock: <FourBlockIcon />,
    exit: <ExitIcon />,
    // sidebarRight
    arrowRight: <ArrowRight />,
    notification: <NotificationIcon />,
    notificationsUser: <NotificationsUser />,
    checkBooking: <CheckBooking />,
    // card
    add: <AddIcon />,
    remove: <RemoveIcon />,
    rotate: <RotateIcon />,
    chart: <ChartIcon />,
    // modal
    close: <CloseIcon />,
    search: <SearchIcon />,
    // profile
    delete: <DeleteIcon />,
    download: <DownloadIcon />,
    clearInput: <ClearInputIcon />,
    sortingAsc: <SortingASCIcon />,
    sortingDesc: <SortingDescIcon />,
    plus: <PluseIcon />
};

export const Icon: React.FC<PropsIcon> = ({ name, style, ...props }): JSX.Element => {
    return (
        <StyledIcon {...props} style={style}>
            {icons[name]}
        </StyledIcon>
    );
};
