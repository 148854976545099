import { FormikInput } from "shared/components/FormikInput"
import { WrapperFormProfile } from "shared/styles/GlobalStyle"
import { PlaceData } from "../../consts/SubPersonal/PlaceData"

export const PlaceDataInput = () => {
    return (
        <WrapperFormProfile>
            <div className="title">Место прописки</div>
            <div className="container">
                    {PlaceData.map((data, index) => (
                        <div className="row" key={index}>
                            {data.map((item, index) => (
                                <FormikInput
                                    key={index}
                                    name={item.name}
                                    label={item.label}
                                    placeholder={item.placeholder}
                                    type={item.type}
                                    maskConfig={item.maskConfig}
                                    className="inputForm"
                                    $afterIcon="clearInput"
                                />
                            ))}
                        </div>
                    ))}
            </div>
        </WrapperFormProfile>
    )
}