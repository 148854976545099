import { useField, useFormikContext } from 'formik';
import { Input } from 'shared/components/Input';

interface FormikInputProps {
  name: string;
  [key: string]: any;
}

export const FormikInput = ({ name, ...props }: FormikInputProps) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const { validateField } = useFormikContext();

  const clearInput = () => {
    setValue('');
    helpers.setTouched(true);
    validateField(name);
  };

  return (
    <Input
      {...field}
      {...props}
      value={field.value}
      fieldProps={field}
      clearInput={clearInput}
      className={meta.touched && meta.error ? 'error' : ''}
    />
  );
};
