export const linksCalendar = [
    {
        name: "День",
        link: "/calendar/day"
    },
    {
        name: "Неделя",
        link: "/calendar/week"
    },
    {
        name: "Месяц",
        link: "/calendar/month"
    },
]