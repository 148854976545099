import 'react-big-calendar/lib/sass/styles.scss';
import "react-big-calendar/lib/addons/dragAndDrop/styles.scss"
import moment, { Moment } from "moment";
import { Calendar, momentLocalizer, View } from "react-big-calendar";
import { WrapperCalendar } from "./style";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { openSidebar } from 'shared/redux/slice/sidebar';
import { useDispatch } from 'react-redux';

interface Event {
    allDay?: boolean | undefined;
    title?: React.ReactNode | undefined;
    start?: Date | undefined;
    end?: Date | undefined;
    resource?: any;
}

interface IPropsReactBigCalendar {
    views: any;
    defaultView: View;
    min: Date;
    max: Date;
    currentDay: Moment; 
    onDateChange: (date: Moment) => void;
    events?: Event[] | undefined;
    resources?: any;
    components?: any;
}

export const ReactBigCalendar = ({
    views,
    defaultView,
    min,
    max,
    currentDay,
    onDateChange,
    events,
    resources,
    components,
}: IPropsReactBigCalendar) => {
    const dispatch = useDispatch();
    const localizer = momentLocalizer(moment);
    const DnDCalendar = withDragAndDrop(Calendar);

    const onSelectSlot = (e: any) => {
        dispatch(openSidebar('BookingComponent'))
    }

    return (
        <WrapperCalendar>
            <DnDCalendar
                localizer={localizer}
                views={views}
                defaultView={defaultView}
                timeslots={2}
                step={15}
                min={min}
                max={max}
                date={currentDay.toDate()}
                onNavigate={(date) => onDateChange(moment(date))}
                toolbar={false}
                events={events}
                resources={resources}
                components={components}
                selectable
                onSelectSlot={onSelectSlot}
                // draggableAccessor={(event) => true}
            />
        </WrapperCalendar>
    )
}