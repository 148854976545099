import { TabLinkStyled, WrapperTabLink } from "./style"

export interface IPropsTabLink {
    name: string;
    link: string;
}

export interface IPropsListTabs {
    list: IPropsTabLink[];
}

export const TabLink = ({
    list,
}: IPropsListTabs) => {
    return (
        <WrapperTabLink>
            {list.map((item, index) => (
                <TabLinkStyled key={index} to={item.link}>
                    {item.name}
                </TabLinkStyled>
            ))}
        </WrapperTabLink>
    )
}