import React, { useState, useRef, useLayoutEffect } from "react";
import { TabsContainer, TabHeaders, TabHeader, Underline, TabContent } from "./style";

interface IPropsTab {
  label: string;
  children: React.ReactNode;
}

interface TPropsTabs {
  children: React.ReactElement<IPropsTab>[];
  onTabChange?: (activeTab: number) => void;
}
  
export const Tabs = ({ children, onTabChange }: TPropsTabs) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabRefs = useRef<(HTMLDivElement | null)[]>([]);

  useLayoutEffect(() => {
    if (tabRefs.current[activeTab]) {
      const { offsetWidth, offsetLeft } = tabRefs.current[activeTab]!;
      setUnderlineStyle({ width: offsetWidth, left: offsetLeft });
    }
  }, [activeTab]);

  const [underlineStyle, setUnderlineStyle] = useState({
    width: 0,
    left: 0,
  });

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    if (onTabChange) {
      onTabChange(index);
    }
  };

  return (
    <TabsContainer className="tabContainer">
      <TabHeaders className="tabHeaders">
        {React.Children.map(children, (child, index) => (
          <TabHeader
            key={index}
            ref={(el) => (tabRefs.current[index] = el)}
            onClick={() => handleTabClick(index)}
            isActive={index === activeTab}
            className={`tabHeader ${index === activeTab ? "active" : ""}`}
          >
            <span>{child.props.label}</span>
          </TabHeader>
        ))}
        <Underline className="underline" animate={underlineStyle} transition={{ duration: 0.3 }} />
      </TabHeaders>
      <TabContent className="tabContent">{children[activeTab]}</TabContent>
    </TabsContainer>
  );
};

export const Tab = ({ children }: IPropsTab) => {
    return <>{children}</>;
};