import { SidebarProvider } from "features/RightSidebar/components/SidebarProvider";
import { LeftSidebar } from "features/LeftSidebar";
import { RightSidebar } from "features/RightSidebar";
import { StyledWrapper, StyledContainer, StyledContent, StyledHeader } from "./style";
import { useSelector } from "react-redux";
import { isModal, isNotification, RootState } from "shared/redux/store";
import { Notifications } from "shared/components/Notifications";
import { Modal } from "shared/components/Modal";

interface IPropsDashboardLayout {
    children: React.ReactNode,
    header: React.ReactNode
}

export const DashboardLayout = ({ 
    children,
    header
}: IPropsDashboardLayout) => {
    
    const modal = useSelector(isModal)
    const notification = useSelector(isNotification)

    const btnsArray = modal.isConfig?.btns?.map((item, index) => ({
        onClick: item.onClick,
        title: item.title,
        active: item.active,
    })) || [];

    return (
        <SidebarProvider>
            <StyledWrapper>
                <LeftSidebar />
                <StyledContainer>
                    <StyledHeader>
                        {header}
                    </StyledHeader>
                    <StyledContent>
                        {children}
                    </StyledContent>
                </StyledContainer>
                <RightSidebar />
            </StyledWrapper>
            {modal.isOpen && (
                <Modal
                    title={modal.isConfig!.title}
                    isOpen={modal.isOpen} 
                    btns={btnsArray}
                >
                    {modal.isContent}
                </Modal>
            )}
            {notification.isType && (
                <Notifications 
                    title={notification.isType} 
                    message={notification.isMessage}
                />
            )}
        </SidebarProvider>
    );
};