export const getChangedValues = (initialValues: any, newValues: any) => {
    const changedValues: any = {};

    Object.keys(newValues).forEach((key) => {
        if (typeof newValues[key] === 'object' && newValues[key] !== null) {
            const nestedChanges = getChangedValues(initialValues[key], newValues[key]);
            if (Object.keys(nestedChanges).length > 0) {
                changedValues[key] = nestedChanges;
            }
        } else {
            if (newValues[key] !== initialValues[key]) {
                changedValues[key] = newValues[key];
            }
        }
    });

    return changedValues;
};
