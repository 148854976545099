import styled from "styled-components";

export const Box = styled.div`
    background: ${props => props.theme.background.white};
    border-radius: 0px 16px 16px 0px;
    width: 100%;
    max-width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const StyledItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 63px;
    position: relative;
    a {
        height: 39px;
        width: 39px;
        border: 1px solid #E1E4EA;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.4s linear;
        &:hover,  &.active {
            background: linear-gradient(0deg, rgba(94, 129, 244, 0.10) 0%, rgba(94, 129, 244, 0.10) 100%),#FFF;
            border: none;
            transition: all 0.4s linear;
            &::after {
                content: "";
                position: absolute;
                right: 0;
                width: 2px;
                height: 48px;
                border-radius: 1px 0px 0px 1px;
                background: #5E81F4;
            }
        }
        div {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`;