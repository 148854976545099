import { AuthWrapper, Box, Wrapper, Title, Description, BackLink } from "../style";
import { Input } from "shared/components/Input";
import { Button } from "shared/components/Button";

interface IPropsForgotPasswordForm {
  setIsForgotPassword: (value: boolean) => void;
}

export const ForgotPasswordForm = ({ setIsForgotPassword }: IPropsForgotPasswordForm) => {
  
  return (
    <AuthWrapper>
        <Box>
            <Wrapper>
            <Title>Восстановление пароля</Title>
            <Description>Введите email, на который придет ссылка для восстановления пароля</Description>
            <Input name="" $beforeIcon="email" label="Email" placeholder="Email" type="email" />
            <Button themeType="primary">Отправить</Button>
            <BackLink onClick={() => setIsForgotPassword(false)}>Назад</BackLink>
            </Wrapper>
        </Box>
    </AuthWrapper>
  );
};
