import { sidebarMenuTypes } from "shared/types/sidebarMenuTypes";

export const sidebarMenuData: sidebarMenuTypes[] = [
    {
        icon: "home",
        link: "/"
    },
    {
        icon: "calendar",
        link: "/calendar/day"
    },
    {
        icon: "users",
        link: "/clients/"
    },
    {
        icon: "fourblock",
        link: "/halls/"
    },
    {
        icon: "folder",
        link: "/folder"
    },
    {
        icon: "presentation",
        link: "/presentation"
    },
    {
        icon: "setting",
        link: "/setting"
    }
];