import { DashboardLayout } from "app/layout"
import { Tabs, Tab } from "shared/components/Tabs"
import { useRef, useState } from "react"
import { Form, Formik } from "formik"
import { TabsWrapper } from "pages/clientsProfile/style";
import { Button } from "shared/components/Button"
import { useNavigate } from "react-router-dom"
import { FormikInput } from "shared/components/FormikInput"
import { StyledItem } from "pages/clientsProfile/style"
import { UploadAvatar } from "shared/containers/UploadAvatar"
import { StyledLine, WrapperFormProfile } from "shared/styles/GlobalStyle"
import { BackButton } from "shared/components/BackButton"

const file = [
    {
        id: 1,
        name: "Паспорт",
    },
    {
        id: 2,
        name: "Права",
    },
    {
        id: 3,
        name: "Документы",
    },
]


export const EquipmentCreate = () => {
    const submitFormRef = useRef<(() => void) | null>(null);
    const [photo, setPhoto] = useState<File | null>(null);

    const navigate = useNavigate();
    const handleClick = (to: string) => {
        navigate(to);
    }
    const handlePhotoChange = (file: File) => {
        setPhoto(file);
    };

    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <StyledItem>
                    <BackButton 
                        text="Назад"
                        onClick={() => handleClick("/clients")}
                    />
                    <div className="dashboard__header-title">Создание профиля</div>
                </StyledItem>
                <div className="dashboard__right-menu">
                    <Button onClick={() => submitFormRef.current && submitFormRef.current()}>Сохранить</Button>
                    <Button themeType="outline-2" onClick={() => handleClick("/clients")}>Отменить</Button>
                </div>
            </div>
        }>
            <TabsWrapper>
                <Tabs>
                    <Tab label="Оборудование">
                        <Formik
                            initialValues={{
                                
                            }}
                            onSubmit={async(values, actions) => {
                                // await axiosApi.post("/api/v1/counterparties/counterparty/", values);
                                actions.setSubmitting(false);
                            }}
                        >
                            {({ submitForm }) => {
                                submitFormRef.current = submitForm;
                                return (
                                    <Form>
                                        <UploadAvatar initialPhoto={null} onPhotoChange={handlePhotoChange} />
                                        <StyledLine margin="20px 0"/>
                                        <WrapperFormProfile>
                                            <div className="title">Добавить оборудование</div>
                                            <div className="container">
                                                <div className="row">
                                                    <FormikInput
                                                        name="fb"
                                                        label="В наличии, шт" 
                                                        placeholder="В наличии, шт"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                    <FormikInput 
                                                        name="hfs"
                                                        label="Доступные к заказу" 
                                                        placeholder="Доступные к заказу"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                    <FormikInput 
                                                        name="xcva"
                                                        label="Заработано всего, ₽" 
                                                        placeholder="Заработано всего, ₽"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                    <FormikInput 
                                                        name="cv"
                                                        label="Ср. кол-во продаж, день" 
                                                        placeholder="Ср. кол-во продаж, день"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                </div>
                                                <div className="row">
                                                    <FormikInput 
                                                        name="xzc12"
                                                        label="Цена, ₽/час" 
                                                        placeholder="Цена, ₽/час"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                    <FormikInput 
                                                        name="wqee"
                                                        label="Цена >3ч, ₽/час" 
                                                        placeholder="Цена >3ч, ₽/час"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                    <FormikInput 
                                                        name="hg3"
                                                        label="Утро, ₽/час" 
                                                        placeholder="Утро, ₽/час"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                    <FormikInput 
                                                        name="hg"
                                                        label="Вечер, ₽/час" 
                                                        placeholder="Вечер, ₽/час"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                </div>
                                                <div className="row">
                                                    <FormikInput 
                                                        name="fd"
                                                        label="Утренние часы" 
                                                        placeholder="Утренние часы" 
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                    <FormikInput 
                                                        name="zx"
                                                        label="Вечерние часы" 
                                                        placeholder="Вечерние часы" 
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                </div>
                                                <div className="row">
                                                    <FormikInput 
                                                        name="v"
                                                        label="Комментарий" 
                                                        placeholder="Комментарий" 
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                </div>
                                            </div>
                                        </WrapperFormProfile>
                                        <WrapperFormProfile>
                                            <div className="title">Скидка от количества забронированных часов</div>
                                            <div className="container">
                                                <div className="row">
                                                    <FormikInput 
                                                        name="wqe"
                                                        label="От N часов" 
                                                        placeholder="От N часов"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                    <FormikInput 
                                                        name="asd"
                                                        label="Скидка, %" 
                                                        placeholder="Скидка, %"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                    <FormikInput 
                                                        name="discount"
                                                        label="Скидка, ₽" 
                                                        placeholder="Скидка, ₽"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                </div>
                                            </div>
                                        </WrapperFormProfile>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Tab>
                    <Tab label="Расходные материалы">
                    <Formik
                            initialValues={{
                                
                            }}
                            onSubmit={async(values, actions) => {
                                // await axiosApi.post("/api/v1/counterparties/counterparty/", values);
                                actions.setSubmitting(false);
                            }}
                        >
                            {({ submitForm }) => {
                                submitFormRef.current = submitForm;
                                return (
                                    <Form>
                                        <UploadAvatar initialPhoto={null} onPhotoChange={handlePhotoChange} />
                                        <StyledLine margin="20px 0"/>
                                        <WrapperFormProfile>
                                            <div className="title">Добавить оборудование</div>
                                            <div className="container">
                                                <div className="row">
                                                    <FormikInput
                                                        name="fb"
                                                        label="В наличии, шт" 
                                                        placeholder="В наличии, шт"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                    <FormikInput 
                                                        name="hfs"
                                                        label="Доступные к заказу" 
                                                        placeholder="Доступные к заказу"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                    <FormikInput 
                                                        name="xcva"
                                                        label="Заработано всего, ₽" 
                                                        placeholder="Заработано всего, ₽"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                    <FormikInput 
                                                        name="cv"
                                                        label="Ср. кол-во продаж, день" 
                                                        placeholder="Ср. кол-во продаж, день"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                </div>
                                                <div className="row">
                                                    <FormikInput 
                                                        name="xzc12"
                                                        label="Цена, ₽/час" 
                                                        placeholder="Цена, ₽/час"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                    <FormikInput 
                                                        name="wqee"
                                                        label="Цена >3ч, ₽/час" 
                                                        placeholder="Цена >3ч, ₽/час"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                    <FormikInput 
                                                        name="hg3"
                                                        label="Утро, ₽/час" 
                                                        placeholder="Утро, ₽/час"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                    <FormikInput 
                                                        name="hg"
                                                        label="Вечер, ₽/час" 
                                                        placeholder="Вечер, ₽/час"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                </div>
                                                <div className="row">
                                                    <FormikInput 
                                                        name="fd"
                                                        label="Утренние часы" 
                                                        placeholder="Утренние часы" 
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                    <FormikInput 
                                                        name="zx"
                                                        label="Вечерние часы" 
                                                        placeholder="Вечерние часы" 
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                </div>
                                                <div className="row">
                                                    <FormikInput 
                                                        name="v"
                                                        label="Комментарий" 
                                                        placeholder="Комментарий" 
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                </div>
                                            </div>
                                        </WrapperFormProfile>
                                        <WrapperFormProfile>
                                            <div className="title">Скидка от количества забронированных часов</div>
                                            <div className="container">
                                                <div className="row">
                                                    <FormikInput 
                                                        name="wqe"
                                                        label="От N часов" 
                                                        placeholder="От N часов"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                    <FormikInput 
                                                        name="asd"
                                                        label="Скидка, %" 
                                                        placeholder="Скидка, %"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                    <FormikInput 
                                                        name="discount"
                                                        label="Скидка, ₽" 
                                                        placeholder="Скидка, ₽"
                                                        className="inputForm"
                                                        $afterIcon="clearInput"
                                                    />
                                                </div>
                                            </div>
                                        </WrapperFormProfile>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Tab>
                </Tabs>
            </TabsWrapper>
        </DashboardLayout>
    );
}