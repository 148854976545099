import { uploadFile, uploadMultipleFiles } from 'shared/utils/upload';

export const useFileUploadUp = () => {
    const handleFileUpload = async (photo: File | null, files: Array<File>) => {
        // Загрузка фото
        const finalUploadedPhotoInfo = photo ? await uploadFile(photo) : null;

        // Загрузка других файлов
        const finalUploadedFilesInfo = files.length > 0 ? await uploadMultipleFiles(files) : [];

        return {
            uploadedPhotoInfo: finalUploadedPhotoInfo,
            uploadedFilesInfo: finalUploadedFilesInfo,
        };
    };

    return {
        handleFileUpload,
    };
};