import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SidebarState {
  isOpen: boolean;
  component: string | null;
}

const initialState: SidebarState = {
  isOpen: false,
  component: null,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    openSidebar: (state, action: PayloadAction<string>) => {
      state.isOpen = true;
      state.component = action.payload;
    },
    closeSidebar: state => {
      state.isOpen = false;
      state.component = null;
    },
    toggleSidebar: state => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { openSidebar, closeSidebar, toggleSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
