import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {ReactNode} from "react";

interface ModalStateConfingBtns {
    onClick: () => void;
    title: string;
    active: boolean;
}

interface ModalStateConfing {
    btns?: ModalStateConfingBtns[] | null;
    title: string;
}

interface ModalState {
    isOpen: boolean;
    config: ModalStateConfing | null;
    content: string | ReactNode | null;
}

const initialState: ModalState = {
    isOpen: false,
    content: null,
    config: null
};

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal: (state, action: PayloadAction<ModalState>) => {
            state.isOpen = true;
            state.content = action.payload.content;
            state.config = action.payload.config;
        },
        closeModal: state => {
            state.isOpen = false;
            state.content = null;
            state.config = null;
        },
    },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
