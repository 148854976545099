import { Outlet, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'shared/redux/hooks';
import { WrapperLoader } from './style';
import { useEffect } from 'react';

export const PrivateRoute = () => {
    const { isAuth, isAuthInProgress } = useAppSelector((state) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthStatus = async () => {
            if (!isAuthInProgress) {
                if (isAuth === false) {
                    navigate('/auth/sign-in');
                }
            }
        };
        checkAuthStatus();
    }, [isAuth, isAuthInProgress, navigate]);

    if (!isAuth && !isAuthInProgress) {
        return (
            <WrapperLoader>
                <div className="loader"></div>
            </WrapperLoader>
        );
    }

    return isAuth ? <Outlet /> : null;
};