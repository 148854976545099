import styled from "styled-components";

export const AuthWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: white url("/bg.png") center / cover no-repeat;
`;

export const Box = styled.div `
  background: white;
  padding: 40px 50px;
  border-radius: 16px;
  width: 100%;
  max-width: 364px;
`;

export const RowCode = styled.div`
  .react-code-input {
    display: flex!important;
    justify-content: space-between!important;
    font-family: "Inter", sans-serif;
  }
  input {
    width: 45px!important;
    height: 86px!important;
    border: none!important;
    border-bottom: 2px solid #E1E4EA!important;
    color: #282A42!important;
    font-size: 71.287px!important;
    font-style: normal!important;
    font-weight: 400!important;
    line-height: normal!important;
    box-shadow: none!important;
    border-radius: 0px!important;
    font-family: "Inter", sans-serif!important;
    margin: 0!important;
    padding: 0!important;
    &::placeholder {
      color: #CDCDDF!important;
    }
  }
`;

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .wrapper-input {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    input {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .error-message {
      margin-top: -15px;
      color: #FC3F1D;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
    }
  }
  .buttonAuth {
    height: 50px;
  }
`;

export const Title = styled.div`
  font-family: "Inter", sans-serif;
  color: #282A42;
  font-size: 27px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
`;

export const Description = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  color: #8181A5;
`;

export const ForgotPasswordLink = styled.div`
  text-align: right;
  color: #8181A5;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const BackLink = styled.span`
  color: #5E81F4;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
`;